import React from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import * as Datetime from 'react-datetime';
import _, { sum, times, cloneDeep, get, isEmpty, set } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import constant from '../constants/config.js';

const stringReplaceAll = (string, values) => {
  return string.replace(/\{\d\}/g, c => values[c.match(/[^{\}]+(?=})/g)]);
};

// const {
//   INCENTIVE,
//   COMPITITIONS,
//   PRODUCT_SAMPLE,
//   INCENTIVE_COMPITITIONS,
//   INCENTIVE_SAMPLE
// } = constant.campaignRequestTypes;

// Business Logic for components
const getCampaignDetails = campaignData => {
  let campaignDetails = campaignData.campaignDetail;
  return campaignDetails;
};

const getQuesAnswerList = campaignData => {
  return campaignData.campaignDetails
    ? campaignData.campaignDetails.questionAnswerList
    : '';
};

const getCampaignData = campaignData => {
  let campaign = campaignData;
  return campaign;
};

const getCampaignStatus = (status, isProcessed = false) => {
  let statusname;
  switch (status) {
    case '1':
    case '4': {
      statusname = 'Draft';
      break;
    }
    case '3': {
      if (isProcessed) {
        statusname = 'Published';
      } else {
        statusname = 'Completed';
      }

      break;
    }
    case '5': {
      statusname = 'Deleted';
      break;
    }
    case '2':
    case '6':
    case '7':
    case '8':
    case '12': {
      statusname = 'Awaiting Promotigo ID';
      break;
    }
    case '9': {
      statusname = 'Cancelled';
      break;
    }
    default:
      break;
  }
  return statusname;
};

const getCampaignConsentIdDetails = campaign => {
  let consentid = {};
  let campaigndetails = campaign.campaignDetail;
  if (
    campaigndetails &&
    campaigndetails.additionalOptinList &&
    campaigndetails.additionalOptinList.length > 0
  ) {
    for (let i = 0; i <= campaigndetails.additionalOptinList.length - 1; i++) {
      if (campaigndetails.additionalOptinList[i]['type'] === 'optin') {
        consentid['optinid'] =
          campaigndetails.additionalOptinList[i]['optinId'];
      } else if (campaigndetails.additionalOptinList[i]['type'] === 'brand') {
        consentid['brandid'] =
          campaigndetails.additionalOptinList[i]['optinId'];
      } else if (
        campaigndetails.additionalOptinList[i]['type'] === 'unilever'
      ) {
        consentid['unileverid'] =
          campaigndetails.additionalOptinList[i]['optinId'];
      }
    }
  }
  return consentid;
};

const getFormatedDate = (datevalue, value = false, format = false) => {
  if (value) {
    let formateddate = Datetime.moment(datevalue)
      .utc()
      .format('DD/MM/YY - HH:mm ');
    return formateddate.split('-').join(' at ');
  } else if (format) {
    return Datetime.moment(datevalue)
      .utc()
      .format('DD/MM/YY HH:mm A');
  } else {
    return Datetime.moment(datevalue)
      .utc()
      .format('DD/MM/YY - HH:mm ');
  }
};

const getNewFormatedDate = (datevalue, format) => {
  return Datetime.moment(datevalue)
    .utc()
    .format(format || 'DD/MM/YY');
};

const getNewFormatedTime = (datevalue, format) => {
  return Datetime.moment(datevalue)
    .utc()
    .format(format || 'hh:mm A');
};

const getDateInDDmmmYYYY = datevalue => {
  return Datetime.moment(datevalue).format('DD MMM YYYY');
};
const getDateInDDmmmYYYYTime = datevalue => {
  return Datetime.moment.utc(datevalue).format('DD MMM YYYY hh:mm a UTC');
};
const getFormatedDateNew = datevalue => {
  let date = new Date(Date.parse(datevalue));
  return (
    date.getDate() +
    '-' +
    date.toLocaleString('default', { month: 'short' }) +
    '-' +
    date.getFullYear()
  );
};

const textCapitalization = args => {
  if (args) {
    return args
      .split(' ')
      .map(ele => ele.charAt(0).toUpperCase() + ele.slice(1))
      .join(' ');
  } else {
    return '';
  }
};

const getSplitDashboardArray = dataArr => {
  var newArr = {};
  newArr['id'] = [];
  newArr['value'] = [];

  dataArr.forEach((item, index) => {
    if (item.constructor === Object) {
      newArr['id'].push(item.value.split('-')[1]);
      newArr['value'].push(item.label);
    } else {
      newArr['id'].push(item);
      newArr['value'].push(item);
    }
  });
  return newArr;
};

const sortArray = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    let result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const numberFormat = num => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const isAEMTemplateCampaign = campaign => {
  let campaignWebsiteType =
    campaign && campaign.websiteType && campaign.websiteType.name
      ? campaign.websiteType.name.toLowerCase()
      : '';
  let campaignformtype = campaign && campaign.type ? campaign.type : '';
  let isAutogenerate =
    campaign && (campaign.autogenerate || campaign.autogenerate === undefined)
      ? true
      : false;
  //Code commented to disable GIGYA Flow
  // let isTrackingRequired =
  //   campaign &&
  //   campaign.campaignDetail &&
  //   campaign.campaignDetail.isTrackingRequired
  //     ? true
  //     : false;

  let simpleSignupWithAutogen =
    campaign &&
    (campaign.campaignType === 'Direct messaging' ||
      campaign.campaignType === 'Simple sign up') &&
    campaignformtype === 'Simple sign-up' &&
    ((campaign.nonGigyaSignupForm && campaign.autogenerate) ||
      (campaign.nonGigyaSignupForm === undefined &&
        campaign.campaignDetail &&
        campaign.campaignDetail.isTrackingRequired))
      ? true
      : false;

  let isQnAsignUp =
    campaignformtype && campaignformtype === 'Sign-up with Questions & Answers'
      ? true
      : false;
  let autogenerateTypeCampaign =
    campaignWebsiteType === 'standalone' ||
    campaignWebsiteType === 'adobe templated' ||
    campaignWebsiteType === 'adobe classic'
      ? true
      : false;

  let isIncentive =
    campaign &&
    campaign.campaignType &&
    (campaign.campaignType === constant.campaignRequestTypes.INCENTIVE ||
      campaign.campaignType ===
        constant.campaignRequestTypes.INCENTIVE_COMPITITIONS ||
      campaign.campaignType === constant.campaignRequestTypes.INCENTIVE_SAMPLE)
      ? true
      : false;

  if (
    (isQnAsignUp && autogenerateTypeCampaign && isAutogenerate) ||
    (!isIncentive &&
      !isQnAsignUp &&
      //Code commented to disable GIGYA Flow
      //isTrackingRequired &&
      simpleSignupWithAutogen &&
      autogenerateTypeCampaign) ||
    (isIncentive && autogenerateTypeCampaign && !isQnAsignUp && isAutogenerate)
  ) {
    return true;
  } else {
    return false;
  }
};

const isAEMTypeCampaign = campaign => {
  let campaignWebsiteType =
    campaign && campaign.websiteType && campaign.websiteType.name
      ? campaign.websiteType.name.toLowerCase()
      : '';
  let isAemTypeCampaign =
    campaignWebsiteType === 'standalone' ||
    campaignWebsiteType === 'adobe templated' ||
    campaignWebsiteType === 'adobe classic'
      ? true
      : false;

  return isAemTypeCampaign;
};

const showGigyaIdScreen = campaign => {
  let showgigyIdScreen = false;
  let isTrackingRequired =
    campaign &&
    campaign.campaignDetail &&
    campaign.campaignDetail.isTrackingRequired
      ? true
      : false;
  let isDirectCampaign =
    campaign &&
    campaign.campaignType &&
    campaign.campaignType === 'Direct messaging'
      ? true
      : false;
  let isSimpleSignUp =
    campaign &&
    campaign.type &&
    campaign.type.toLowerCase() === constant.SIMPLE_SIGNUP_TEXT.toLowerCase()
      ? true
      : false;
  let campaignWebsiteType =
    campaign && campaign.websiteType && campaign.websiteType.name
      ? campaign.websiteType.name.toLowerCase()
      : '';
  if (
    (isDirectCampaign &&
      isSimpleSignUp &&
      !isTrackingRequired &&
      (campaignWebsiteType === 'standalone' ||
        campaignWebsiteType === 'adobe templated' ||
        campaignWebsiteType === 'adobe classic')) ||
    (isDirectCampaign &&
      isSimpleSignUp &&
      campaignWebsiteType !== 'standalone' &&
      campaignWebsiteType !== 'adobe templated' &&
      campaignWebsiteType !== 'adobe classic' &&
      campaignWebsiteType !== 'adobe saas')
  ) {
    showgigyIdScreen = true;
  }
  return showgigyIdScreen;
};

const getUserCountryList = rawCountryData => {
  if (rawCountryData.length) {
    let processedCountryData = [];
    processedCountryData = rawCountryData.map(data => {
      return {
        value: data.code + '-' + data._id,
        label: data.name
      };
    });
    let sortedprocessedCountryData = processedCountryData.sort(
      sortArray('label')
    );
    return sortedprocessedCountryData;
  }
};

const scrollToTop = () => {
  window.scroll({
    top: -100,
    left: 0,
    behavior: 'smooth'
  });
};

const statusBadge = campaign => {
  let isPublished = campaign && campaign.isProcessed ? true : false;
  let isAEMTemplate = isAEMTemplateCampaign(campaign);
  return {
    'mt-3': true,
    badge: true,
    'badge-success':
      campaign && campaign.status === '3'
        ? isAEMTemplate
          ? isPublished
            ? true
            : false
          : true
        : false,
    'badge-warning':
      (campaign && campaign.status === '6') ||
      (campaign && campaign.status === '7') ||
      (campaign && campaign.status === '8') ||
      (campaign && campaign.status === '3'
        ? isAEMTemplate
          ? isPublished
            ? false
            : true
          : false
        : true),
    'badge-primary':
      campaign && (campaign.status === '1' || campaign.status === '4'),
    'badge-secondary': campaign && campaign.status === '9'
  };
};

const getCampaignStatusText = (campaign, userrole) => {
  let isPublished = campaign && campaign.isProcessed ? true : false;
  let isAEMTemplate = isAEMTemplateCampaign(campaign);
  let showGigyaId = showGigyaIdScreen(campaign);
  let status = campaign && campaign.status;

  let statusname;
  switch (status) {
    case '1':
    case '4': {
      statusname = 'Draft';
      break;
    }
    case '2': {
      statusname = 'Processing';
      break;
    }
    case '3': {
      statusname = 'Completed';
      if (isAEMTemplate) {
        if (isPublished) {
          statusname = 'Published';
        } else {
          statusname = 'Processing';
        }
      }
      break;
    }
    case '5': {
      statusname = 'Deleted';
      break;
    }
    case '6': {
      statusname =
        // userrole === constant.roles.REQUESTOR
        //   ? 'Processing'
        //   :
        'Awaiting approval';
      break;
    }
    case '7': {
      statusname =
        // userrole === constant.roles.REQUESTOR
        //   ? 'Processing'
        //   :
        'Awaiting Master data';
      break;
    }
    case '8': {
      if (showGigyaId) {
        statusname =
          // userrole === constant.roles.REQUESTOR
          //   ? 'Processing'
          //   :
          'Awaiting Gigya keys';
      } else {
        statusname =
          // userrole === constant.roles.REQUESTOR ? 'Processing' :
          'Published';
      }
      break;
    }
    case '9': {
      statusname = 'Cancelled';
      break;
    }
    case '12': {
      statusname = 'Awaiting Promotigo ID';
      break;
    }
    default:
      break;
  }
  return statusname;
};
const getDateRange = text => {
  let returnDateRange = '';

  switch (text) {
    case 'today': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'last 7 days': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(7, 'days')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'last 14 days': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(14, 'days')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'last 30 days': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(30, 'days')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'last 60 days': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(60, 'days')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'last 90 days': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(90, 'days')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'last month': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(1, 'months')
          .startOf('month')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .subtract(1, 'months')
          .endOf('month')
          .format('DD/MM/YY');
      break;
    }
    case 'past year': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .subtract(1, 'year')
          .startOf('year')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .subtract(1, 'year')
          .endOf('year')
          .format('DD/MM/YY');
      break;
    }
    case 'this year': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .startOf('year')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }
    case 'this and last year': {
      returnDateRange =
        Datetime.moment()
          .utc()
          .startOf('year')
          .subtract(1, 'year')
          .format('DD/MM/YY') +
        ' - ' +
        Datetime.moment()
          .utc()
          .format('DD/MM/YY');
      break;
    }

    default:
      break;
  }

  return returnDateRange;
};

let getParentRoutes = (routes, parentId, match) => {
  if (parentId) {
    let nodes = [];
    let parentdata = _.find(routes, ['uid', parentId]);

    if (parentdata.parent) {
      nodes = [
        {
          title: parentdata.label,
          path: parentdata.path.replace(/:id/g, match.params.id)
        }
      ];
      let nodesData = getParentRoutes(routes, parentdata.parent, match);
      nodes = nodesData.concat(nodes);
      nodes = _.uniq(nodes, 'label');
      return nodes;
    } else {
      nodes = [
        {
          title: parentdata.label,
          path: parentdata.path.replace(/:id/g, match.params.id)
        }
      ];
      return nodes;
    }
  }
};

const getPageBreadCrumb = (match, routes) => {
  if (match) {
    let pathdetail = _.find(routes, ['path', match.path]);
    let breadcrumbData = [];
    if (pathdetail && pathdetail.parent) {
      breadcrumbData = getParentRoutes(routes, pathdetail.parent, match);
    } else {
      if (pathdetail) {
        breadcrumbData.push({
          title: pathdetail.label,
          path: pathdetail.path.replace(/:id/g, match.params.id)
        });
      }
    }
    if (pathdetail && pathdetail.showCurrent) {
      breadcrumbData.push({
        title: pathdetail.label,
        path: pathdetail.path.replace(/:id/g, match.params.id)
      });
    }
    return breadcrumbData;
  }
};

const generateRoleStatusBasedUrl = (uid, role, status, campaignType, type) => {
  let url = '/campaign-wizard/campaign/' + uid;
  if (
    (role === constant.roles.APPROVER || role === constant.roles.ADMIN) &&
    status === constant.campaignStatus.WAITING_APPROVAL
  ) {
    return url + '/approval';
  } else if (
    role === constant.roles.MASTERDATA &&
    status === constant.campaignStatus.WAITING_ID
  ) {
    return url + '/masterdata';
  } else if (
    role === constant.roles.GIGYA &&
    status === constant.campaignStatus.WAITING_API_DETAILS &&
    campaignType &&
    campaignType.toLowerCase() === 'direct messaging' &&
    type &&
    type.toLowerCase() === constant.SIMPLE_SIGNUP_TEXT.toLowerCase()
  ) {
    return url + '/gigya';
  } else if (
    role === constant.roles.REQUESTOR &&
    status === constant.campaignStatus.WAITING_FORM_ID &&
    campaignType &&
    campaignType.toLowerCase() === 'incentive'
  ) {
    return url + '/promotigo-info';
  } else {
    return url;
  }
};

const checkCampaignLanguage = campaignLanguage => {
  return campaignLanguage && campaignLanguage.toLowerCase() === 'english'
    ? true
    : false;
};

const showCampaignLanguage = campaignLanguage => {
  return campaignLanguage && campaignLanguage.toLowerCase() !== 'english'
    ? ' (' + campaignLanguage + ' translation)'
    : '';
};

const isQuestionMandatory = questionOption => {
  return questionOption ? 'Yes' : 'No';
};

const isQuesQualifying = questionOption => {
  return questionOption ? 'Yes' : 'No';
};

const isRestrictionAllowed = questionOption => {
  return questionOption ? 'Yes' : 'No';
};
const isCampaignEdited = (
  fieldName,
  editedData,
  index,
  label,
  value,
  parentIndex
) => {
  if (fieldName && Object.keys(editedData).length) {
    let updatedFields = Object.keys(editedData);
    return Object.keys(editedData).indexOf(fieldName) !== -1 ? true : false;
  }
  return false;
};

const showCampaignEditButton = campaign => {
  let isAEMTemplate = isAEMTemplateCampaign(campaign);
  let isAEMFlow = hasAEMFlow(campaign);
  let cwStatus = getCampaignStatus(campaign.status);
  let isWebsiteTypeSAAS =
    (campaign &&
      campaign.websiteType &&
      campaign.websiteType.name === 'Adobe SaaS') ||
    campaign.isPlatformAgnostic;

  if (
    cwStatus === 'Completed' &&
    campaign &&
    campaign.campaignType === 'Direct messaging' &&
    campaign &&
    campaign.type === constant.SIMPLE_SIGNUP_TEXT &&
    !isAEMTemplate &&
    !isAEMFlow &&
    !isWebsiteTypeSAAS
  ) {
    return true;
  }
  return false;
};

const str2bool = value => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};

const masterDataCampaignStatus = (
  campaignType,
  type,
  isTracking,
  websiteType,
  incentiveItPartner,
  isMasterData
) => {
  let status = constant.campaignStatus.COMPLETED;
  //Code commented to disable GIGYA Flow
  // let isTrackingRequired = isTracking ? true : false;
  // let isDirectCampaign = campaignType === "Direct messaging" ? true : false;
  // let isSimpleSignUp =
  //   type.toLowerCase() === constant.SIMPLE_SIGNUP_TEXT.toLowerCase()
  //     ? true
  //     : false;
  let campaignWebsiteType = websiteType ? websiteType.toLowerCase() : '';
  let isAEMPlatformType =
    campaignWebsiteType === 'standalone' ||
    campaignWebsiteType === 'adobe templated' ||
    campaignWebsiteType === 'adobe classic' ||
    campaignWebsiteType === 'adobe saas'
      ? true
      : false;
  //Code commented to disable GIGYA Flow
  //let isCampaignTypeSaas = campaignWebsiteType === "adobe saas";
  // if (
  //   (isDirectCampaign &&
  //     isSimpleSignUp &&
  //     !isTrackingRequired &&
  //     isAEMPlatformType) ||
  //   (isDirectCampaign &&
  //     isSimpleSignUp &&
  //     !isAEMPlatformType &&
  //     !isCampaignTypeSaas)
  // ) {
  //   status = constant.campaignStatus.WAITING_API_DETAILS;
  // } else
  if (
    (campaignType &&
      type &&
      campaignType === 'Incentive' &&
      incentiveItPartner &&
      incentiveItPartner === 'promotigo') ||
    (campaignType && type && campaignType === 'Incentive' && !isMasterData)
  ) {
    status = constant.campaignStatus.WAITING_FORM_ID;
  }
  return status;
};

//generic method to generate the UUID for campaigns
const _generateUUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid.split('-')[0];
};

const checkMarketSupportedForDoubleOptin = (
  countryCode,
  marketAllowed = []
) => {
  let isMarketAllowerd = marketAllowed.includes(countryCode) ? true : false;
  return isMarketAllowerd;
};

let getSmsCampaignStatusText = smsStatus => {
  let smsStatusText;
  switch (smsStatus) {
    case '1': {
      smsStatusText = 'Draft';
      break;
    }
    case '2': {
      smsStatusText = 'Submitted';
      break;
    }
    default:
      break;
  }
  return smsStatusText;
};

/**
 * Function will check if the input language is an RTL language
 * @param {*} language : Language code
 * @returns Boolean
 */
let isLanguageRTL = language => {
  let RTLLanguages = constant.RTL_LANGUAGE_CODES;
  return RTLLanguages.includes(language.toLowerCase());
};

let isGigyaKeySubmitted = campaign => {
  return campaign &&
    campaign.type === 'Simple sign-up' &&
    campaign.campaignDetail &&
    campaign.campaignDetail.profileStore &&
    campaign.campaignDetail.profileStore.gigyaProductionProfileStoreKey
    ? true
    : false;
};

let showAutoGenerateFlag = campaign => {
  let isSimpleSignUp = campaign && campaign.type === 'Simple sign-up';
  let simpleSignupAutoGen =
    campaign.campaignType === 'Direct messaging' &&
    isSimpleSignUp &&
    campaign.nonGigyaSignupForm !== undefined &&
    campaign.nonGigyaSignupForm &&
    isAEMTypeCampaign(campaign)
      ? true
      : false;

  let qnaAutoGen = !isSimpleSignUp && isAEMTypeCampaign(campaign);
  let incentiveAutoGen =
    isAEMTypeCampaign(campaign) && campaign.campaignType === 'Incentive';

  return simpleSignupAutoGen || qnaAutoGen || incentiveAutoGen ? true : false;
};

let epsilonMarket = market => {
  return constant.epsilonMarket.includes(market);
};
let showEditFormForSaas = data => {
  const isLoginRequester =
    (data.userEmail && data.userEmail.toLowerCase()) ===
    (data.campaignDetails &&
      data.campaignDetails.requestersEmail &&
      data.campaignDetails.requestersEmail.toLowerCase());
  if (data.userrole === constant.roles.ADMIN) {
    return true;
  } else if (data.campaignDetails && data.campaignDetails.isEveryoneEditor) {
    return true;
  } else if (isLoginRequester) {
    return true;
  } else if (
    data.campaignDetails &&
    !data.campaignDetails.isEveryoneEditor &&
    data.campaignDetails.users &&
    data.campaignDetails.users.length === 0 &&
    isLoginRequester
  ) {
    return true;
  } else if (
    data.campaignDetails &&
    !data.campaignDetails.isEveryoneEditor &&
    data.campaignDetails.users &&
    data.campaignDetails.users.length > 0
    // && data.campaignDetails.users.includes(data.email)
  ) {
    let emailExist = data.campaignDetails.users.find(
      ele =>
        ele.email &&
        ele.email.toLowerCase() === data.userEmail &&
        data.userEmail.toLowerCase()
    );
    if (emailExist) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isMDRequiredForQnA = campaign => {
  let questions = [];
  let isMDRequired = false;
  questions =
    campaign.campaignDetail &&
    campaign.campaignDetail.fields &&
    campaign.campaignDetail.fields.length > 0
      ? campaign.campaignDetail.fields.filter(
          e => e.dataType === 'questionAnswerPair'
        )
      : [];
  questions.forEach(ques => {
    if (ques.isNew || ques.istranslatedNew) {
      isMDRequired = true;
    } else {
      let answers = [];
      answers = ques.answers;
      answers.forEach(ans => {
        if (ans.isNew || ans.istranslatedNew) {
          isMDRequired = true;
        }
      });
    }
  });
  return isMDRequired;
};

let showMasterDataIcon = data => {
  let isMDActionRequired = false;
  let optinList = [];
  if (data) {
    if (
      (data &&
        data.websiteType &&
        data.websiteType.name === constant.WEBSITETYPE_SAAS) ||
      data.isPlatformAgnostic ||
      data.isMasterData
    ) {
      let consents = [];
      consents =
        data.campaignDetail &&
        data.campaignDetail.consents &&
        data.campaignDetail.consents.length
          ? data.campaignDetail.consents
          : [];
      consents.forEach(ele => {
        if (
          ele.optInType &&
          ele.optInType.includes(constant.ADDITIONAL_SUBSCRIPTION) &&
          (!ele.serviceId || ele.serviceId.trim() === '')
        ) {
          isMDActionRequired = true;
        }
      });

      if (!isMDActionRequired && data.type === constant.SIGNUP_WITH_QA) {
        isMDActionRequired = isMDRequiredForQnA(data);
      }
    } else {
      optinList =
        data && data.campaignDetail && data.campaignDetail.additionalOptinList;
    }
    if (optinList && optinList.length > 0) {
      isMDActionRequired = optinList.filter(elem => elem.id === '').length > 0;
    }
    let qnaList = [];
    qnaList =
      data && data.campaignDetail && data.campaignDetail.questionAnswerList;
    if (!isMDActionRequired && qnaList && qnaList.length > 0) {
      qnaList.forEach(question => {
        if (question.new || question.translatednew) {
          isMDActionRequired = true;
        } else {
          let answerList = [];
          answerList = question.answers;
          answerList.forEach(answer => {
            if (answer.new || answer.translatednew) {
              isMDActionRequired = true;
            }
          });
        }
      });
    }
  }
  return isMDActionRequired;
};

/**
 *
 * @param {*} debounceFunction
 * to debounce api call - to limit nos. of API calls
 * @returns
 */
let debounceFunction = (func, delay) => {
  let timer;
  return function(...args) {
    const context = this;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

/**
 * Function will return the new qna counts for the question answer tile
 * @param {*} data - campaign details object
 * @returns - qnaTileData
 */
let fetchQnANotificationTileData = (data, qnadata) => {
  let newQuestion = 0,
    operationQues = 0,
    enrichmentQues = 0,
    newTranslatedQues = 0,
    newAnswer = 0,
    newTranslatedAns = 0;
  let showQnATile = false,
    showQuesCategory = false;
  let qnaList = [];
  if (data && data.fields && data.fields.length > 0) {
    qnaList = data.fields.filter(ele => ele.dataType === 'questionAnswerPair');
  } else {
    qnaList = qnadata;
  }
  if (qnaList && qnaList.length > 0) {
    qnaList.forEach(ques => {
      if (ques.isNew || ques.new) {
        showQuesCategory = ques.reasonForCreation ? true : false;
        if (showQuesCategory) {
          if (ques.reasonForCreation === 'Data enrichment') {
            enrichmentQues = enrichmentQues + 1;
          } else {
            operationQues = operationQues + 1;
          }
        } else {
          newQuestion = newQuestion + 1;
        }
        showQnATile = true;
      }
      if (ques.istranslatedNew || ques.translatednew) {
        newTranslatedQues = newTranslatedQues + 1;
        showQnATile = true;
      }
      if (
        ques.questionType !== 'Free text' ||
        ques.type === 'Single choice' ||
        ques.type === 'Multiple choice'
      ) {
        let answers = ques.answers;
        if (answers && answers.length > 0) {
          answers.forEach(ans => {
            newAnswer = ans.isNew || ans.new ? newAnswer + 1 : newAnswer;
            newTranslatedAns =
              ans.istranslatedNew || ans.translatednew
                ? newTranslatedAns + 1
                : newTranslatedAns;
            if (newAnswer > 0 || newTranslatedAns > 0) {
              showQnATile = true;
            }
          });
        }
      }
    });
  }

  let qnaTileData = {
    newQuestion: newQuestion,
    operationQues: operationQues,
    enrichmentQues: enrichmentQues,
    newTranslatedQues: newTranslatedQues,
    newAnswer: newAnswer,
    newTranslatedAns: newTranslatedAns,
    showQuesCategory: showQuesCategory,
    showQnATile: showQnATile
  };
  return qnaTileData;
};

/**
 * Function will return unique question catgeories for business filters on QnA overlay
 * @param {*} data
 * @returns
 */
let getDistinctBusinessFilters = data => {
  let allQuestions = data;
  var uniqueFilterName = new Set(allQuestions.map(o => o.category));

  constant.BUSINESS_FILTERS_OVERRIDES.forEach(ele => {
    if (uniqueFilterName.has(ele.key)) {
      uniqueFilterName.delete(ele.key);
      uniqueFilterName.add(ele.displayName);
    }
  });
  if (uniqueFilterName.has(null)) {
    uniqueFilterName.delete(null);
  }
  if (uniqueFilterName.has(undefined)) {
    uniqueFilterName.delete(undefined);
  }
  var filterArray = [];
  filterArray = Array.from(uniqueFilterName).sort();
  return filterArray;
};

const checkIsCIAEnabled = (campaign = {}) => {
  const { country: { code = null } = {} } = campaign;
  if (code) {
    return (
      constant.featureFlags.ENABLE_CONTENT_INTEREST_AREAS &&
      constant.CIA_ENABLED_MARKETS.includes(code)
    );
  }
  return false;
};
const getNewCampaignTypeName = (campaignType, type) => {
  if (campaignType === constant.campaignRequestTypes.DIRECT_MESSAGING) {
    return constant.campaignRequestTypes.SIMPLE_SIGNUP;
  } else if (campaignType === constant.campaignRequestTypes.MASTER_DATA_ONLY) {
    return constant.campaignRequestTypes.MASTER_DATA_REQUEST;
  } else if (
    campaignType === constant.campaignRequestTypes.INCENTIVE &&
    type === constant.campaignRequestTypes.COMPITITIONS
  ) {
    return constant.campaignRequestTypes.INCENTIVE_COMPITITIONS;
  } else if (
    campaignType === constant.campaignRequestTypes.INCENTIVE &&
    type === constant.campaignRequestTypes.PRODUCT_SAMPLE
  ) {
    return constant.campaignRequestTypes.INCENTIVE_SAMPLE;
  }
  return null;
};

const getStepProgressStatus = (index, progressIndex, totalSteps) => {
  function checkIfSumbmited() {
    return progressIndex === 0 ? index === progressIndex + 1 : false;
  }
  if (
    index < progressIndex ||
    index === 0 ||
    progressIndex === totalSteps - 1
  ) {
    return 'is-done';
  } else if (index === progressIndex || checkIfSumbmited()) {
    return 'is-now';
  } else {
    return 'is-later';
  }
};

const getStepProgressDate = (status, step, campaignHistory) => {
  if (status === 'is-done') {
    let event = constant.CAMPAIGN_HISTORY_PROGRESS_STEP_MAP[step.id];
    let eventLog =
      campaignHistory && campaignHistory.length
        ? campaignHistory.find(elem => elem.event === event)
        : '';
    let eventDate = eventLog
      ? moment(eventLog.eventDate).format('DD-MMM-YYYY')
      : moment().format('DD-MMM-YYYY');
    return eventDate;
  } else if (status === 'is-now') {
    return 'Now';
  } else {
    return 'Later';
  }
};

const getCampaignStatusSteps = (campaign = {}, campaignHistory = []) => {
  let progressSteps = [];
  let isMasterDataRequired = showMasterDataIcon(campaign);
  let isPromotigoFormIdRequired =
    (campaign &&
      campaign.campaignType &&
      campaign.campaignType === 'Incentive' &&
      !campaign.isMasterData) ||
    (campaign &&
      campaign.campaignType &&
      campaign.campaignType === 'Incentive' &&
      campaign &&
      campaign.isMasterData &&
      ((campaign.campaignDetail &&
        campaign.campaignDetail.incentiveItPartner &&
        campaign.campaignDetail.incentiveItPartner === 'promotigo') ||
        campaign.incentivePromotionalPartner === 'promotigo'))
      ? true
      : false;
  let progresStepList = constant.CAMPAIGN_TIMELINE_STEPS.filter(elem => {
    if (!isMasterDataRequired && elem.status.includes('7')) {
      return false;
    }
    if (!isPromotigoFormIdRequired && elem.status.includes('12')) {
      return false;
    }
    return true;
  });
  let totalSteps = progresStepList.length;
  let progressIndex = progresStepList.findIndex(elem =>
    elem.status.includes(campaign.status)
  );
  progresStepList.forEach((step, index) => {
    let status = getStepProgressStatus(index, progressIndex, totalSteps);
    let title = step.stepName;
    if (
      step.status.includes('3') &&
      isAEMTypeCampaign(campaign) &&
      !campaign.isPlatformAgnostic &&
      !campaign.isMasterData
    ) {
      title = step.altStepName;
    }
    progressSteps.push({
      title: title,
      completedAt: getStepProgressDate(status, step, campaignHistory),
      status: status,
      id: step.id
    });
  });

  return progressSteps;
};
const getYupSchema = fields => {
  const concatedSchema = {};
  fields.forEach(field => {
    if (field.rules) {
      concatedSchema[field.name] = field.rules;
    }
  });
  return yup.object().shape(concatedSchema);
};

const transformEmailBriefingData = (data = {}, type, isReview = false) => {
  if (type === 'branddetails') {
    return {
      ...data,
      brand: Array.isArray(data.brand)
        ? data.brand.map(d => ({
            id: d._id,
            label: d.name,
            value: d.code
          }))
        : {
            id: data.brand._id,
            label: data.brand.name,
            value: data.brand.code
          },
      country: {
        id: data.country._id,
        label: data.country.name,
        value: data.country.code
      },
      language: {
        id: data.language._id,
        label: data.language.name,
        value: data.language.code
      }
    };
  }
  if (type === 'emailType') {
    return {
      emailType: data.emailType,
      uid: data.uid
    };
  }

  if (type === 'emailDetails') {
    const clonedData = cloneDeep(data);
    let {
      summary = {},
      deliverySchedule = {},
      audienceDefinition = {},
      emailCollaborators = {},
      uid,
      emailType,
      // emailAttributes
      requestInfoAnswerText,
      abTesting
    } = clonedData;

    const { performABTests, abTestElements } = abTesting || {};
    const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

    const emailTriggerType = get(deliverySchedule, 'emailTrigger.type');
    if (emailTriggerType && emailTriggerType === 'fixedDateAndtime') {
      const date = get(
        deliverySchedule,
        'emailTrigger.details.emailDeliveryDate'
      );
      const time = get(
        deliverySchedule,
        'emailTrigger.details.emailDeliveryTime'
      );
      if (date) {
        deliverySchedule.emailTrigger.details.emailDeliveryDate = moment
          .utc(date)
          .toDate();
      }
      if (time) {
        deliverySchedule.emailTrigger.details.emailDeliveryTime = moment
          .utc(time)
          .toDate();
      }

      if (isABTestOnSendTime) {
        const variantADate = get(
          deliverySchedule,
          'emailTrigger.details.emailDeliveryDateVariantA'
        );
        const variantATime = get(
          deliverySchedule,
          'emailTrigger.details.emailDeliveryTimeVariantA'
        );
        const variantBDate = get(
          deliverySchedule,
          'emailTrigger.details.emailDeliveryDateVariantB'
        );
        const variantBTime = get(
          deliverySchedule,
          'emailTrigger.details.emailDeliveryTimeVariantB'
        );
        if (variantADate) {
          deliverySchedule.emailTrigger.details.emailDeliveryDateVariantA = moment
            .utc(variantADate)
            .toDate();
        }
        if (variantATime) {
          deliverySchedule.emailTrigger.details.emailDeliveryTimeVariantA = moment
            .utc(variantATime)
            .toDate();
        }
        if (variantBDate) {
          deliverySchedule.emailTrigger.details.emailDeliveryDateVariantB = moment
            .utc(variantBDate)
            .toDate();
        }
        if (variantBTime) {
          deliverySchedule.emailTrigger.details.emailDeliveryTimeVariantB = moment
            .utc(variantBTime)
            .toDate();
        }
      }
    }

    if (isReview) {
      if (emailTriggerType && emailTriggerType === 'dynamicTrigger') {
        const triggerConditionStartDate = get(
          deliverySchedule,
          'emailTrigger.details.triggerConditionStartDate'
        );
        const triggerConditionEndDate = get(
          deliverySchedule,
          'emailTrigger.details.triggerConditionEndDate'
        );
        if (triggerConditionStartDate) {
          deliverySchedule.emailTrigger.details.triggerConditionStartDate = moment
            .utc(triggerConditionStartDate)
            .format('DD/MM/YYYY');
        }
        if (triggerConditionEndDate) {
          deliverySchedule.emailTrigger.details.triggerConditionEndDate = moment
            .utc(triggerConditionEndDate)
            .format('DD/MM/YYYY');
        }
      }
    }

    const sendEmailToType = get(audienceDefinition, 'sendEmailTo.type.value');
    if (sendEmailToType && sendEmailToType === 'consumersLinkedToCampaignIds') {
      const campaignIds = audienceDefinition.sendEmailTo.details.campaignIds;
      audienceDefinition.sendEmailTo.details.campaignIds = campaignIds.map(
        (campaign, index) => {
          // Why is this needed ?
          return { id: `field_${index + 1}`, value: campaign };
        }
      );
    }
    // if(emailTriggerType === undefined){
    //   if(emailType === 'newsletter'){
    //     deliverySchedule = {
    //       emailTrigger : {
    //         type:'fixedDateAndtime'
    //       }
    //     }
    //   }
    // }

    return {
      summary,
      deliverySchedule,
      audienceDefinition,
      emailCollaborators,
      uid,
      emailType,
      // emailAttributes
      requestInfoAnswerText,
      abTesting
    };
  }
  return data;
};

const transformEmailBriefingPayload = (data, type) => {
  if (type === 'emailCampaignDetails') {
    const clonedData = cloneDeep(data);
    const {
      summary,
      deliverySchedule,
      audienceDefinition,
      userEmail,
      userName,
      status
    } = clonedData;

    if (get(deliverySchedule, 'emailTrigger.type') === 'fixedDateAndtime') {
      const date = deliverySchedule.emailTrigger.details.emailDeliveryDate;
      const time = deliverySchedule.emailTrigger.details.emailDeliveryTime;
      deliverySchedule.emailTrigger.details.emailDeliveryDate = moment
        .utc(date)
        .toDate();
      deliverySchedule.emailTrigger.details.emailDeliveryTime = moment
        .utc(time)
        .toDate();
      delete deliverySchedule.emailTrigger.details.triggerCondition;
    }

    if (get(deliverySchedule, 'emailTrigger.type') === 'dynamicTrigger') {
      const startDate =
        deliverySchedule.emailTrigger.details.triggerCondition.startDate;
      const endDate =
        deliverySchedule.emailTrigger.details.triggerCondition.endDate;
      deliverySchedule.emailTrigger.details.triggerCondition.startDate = moment
        .utc(startDate)
        .toDate();
      deliverySchedule.emailTrigger.details.triggerCondition.endDate = moment
        .utc(endDate)
        .toDate();
    }

    if (
      get(audienceDefinition, 'sendEmailTo.type.value') ===
      'consumersLinkedToCampaignIds'
    ) {
      const campaignIds = audienceDefinition.sendEmailTo.details.campaignIds;
      audienceDefinition.sendEmailTo.details.campaignIds = campaignIds.map(
        campaign => campaign.value
      );
    }

    if (
      get(audienceDefinition, 'sendEmailTo.type.value') ===
      'allConsentedContactableConsumers'
    ) {
      delete audienceDefinition.sendEmailTo.details;
      delete audienceDefinition.sendEmailTo.details;
    }

    if (
      get(audienceDefinition, 'sendEmailTo.type.value') === 'customCriteria'
    ) {
      delete audienceDefinition.sendEmailTo.details.campaignIds;
    }

    return {
      summary: summary,
      deliverySchedule: deliverySchedule,
      audienceDefinition: audienceDefinition,
      userEmail: userEmail,
      userName: userName,
      status: status
    };
  }

  if (type === 'emailSchedule') {
    const clonedData = cloneDeep(data);
    const {
      deliverySchedule,
      userEmail,
      userName,
      status,
      requestInfoText,
      requestInfoAnswerText,
      nmiAssignedToRequestor
    } = clonedData;
    const additionalData = {};
    if (get(deliverySchedule, 'emailTrigger.type') === 'fixedDateAndtime') {
      const date = deliverySchedule.emailTrigger.details.emailDeliveryDate;
      const time = deliverySchedule.emailTrigger.details.emailDeliveryTime;
      if (date) {
        deliverySchedule.emailTrigger.details.emailDeliveryDate = moment
          .utc(date)
          .toDate();
      }
      if (time) {
        deliverySchedule.emailTrigger.details.emailDeliveryTime = moment
          .utc(time)
          .toDate();
      }
      delete deliverySchedule.emailTrigger.details.triggerCondition;
    }

    if (get(deliverySchedule, 'emailTrigger.type') === 'dynamicTrigger') {
      const startDate =
        deliverySchedule.emailTrigger.details.triggerConditionStartDate;
      const endDate =
        deliverySchedule.emailTrigger.details.triggerConditionEndDate;
      const alwaysOn =
        deliverySchedule.emailTrigger.details.triggerConditionAlwaysOn;
      if (startDate) {
        deliverySchedule.emailTrigger.details.triggerConditionStartDate = moment
          .utc(startDate)
          .toDate();
      }
      if (endDate) {
        deliverySchedule.emailTrigger.details.triggerConditionEndDate = moment
          .utc(endDate)
          .toDate();
      }
      if (alwaysOn) {
        delete deliverySchedule.emailTrigger.details.triggerConditionEndDate;
      }
    }

    if (get(deliverySchedule, 'sendAfter.value') === 'DontSend') {
      additionalData['$unset'] = { followUp: '' };
    }

    if (status === constant.EMAIL_STATUS.LIVE) {
      additionalData.completionDate = new Date();
    }

    return {
      deliverySchedule: deliverySchedule,
      userEmail: userEmail,
      userName: userName,
      status: status,
      requestInfoText: requestInfoText,
      requestInfoAnswerText: requestInfoAnswerText,
      nmiAssignedToRequestor: nmiAssignedToRequestor,
      ...additionalData
    };
  }

  if (type === 'emailSummary') {
    const clonedData = cloneDeep(data);
    const { summary, userEmail, userName, status } = clonedData;

    return {
      summary: summary,
      userEmail: userEmail,
      userName: userName,
      status: status
    };
  }

  if (type === 'emailCollaborators') {
    const clonedData = cloneDeep(data);
    const { emailCollaborators, userEmail, userName, status } = clonedData;
    const collaboratorsList = emailCollaborators.collaborators;
    const modifiedData = {
      ...emailCollaborators,
      collaborators:
        collaboratorsList && collaboratorsList.length
          ? collaboratorsList.filter(elem => elem.value !== '')
          : undefined
    };
    if (modifiedData.type !== 'requestorAndSelectedUsers') {
      delete modifiedData.collaborators;
      modifiedData['$unset'] = { collaborators: '' };
    }
    return {
      emailCollaborators: modifiedData,
      userEmail: userEmail,
      userName: userName,
      status: status
    };
  }

  if (type === 'emailAudience') {
    const clonedData = cloneDeep(data);
    const { audienceDefinition, userEmail, userName, status } = clonedData;

    if (
      get(audienceDefinition, 'sendEmailTo.type.value') ===
      'consumersLinkedToCampaignIds'
    ) {
      const campaignIds = audienceDefinition.sendEmailTo.details.campaignIds;
      const campaignIdsList = [];
      campaignIds.forEach(campaign => {
        if (campaign.value && campaign.value.trim() !== '') {
          campaignIdsList.push(campaign.value);
        }
      });
      audienceDefinition.sendEmailTo.details.campaignIds = campaignIdsList;
    }

    if (
      get(audienceDefinition, 'sendEmailTo.type.value') ===
      'allConsentedContactableConsumers'
    ) {
      delete audienceDefinition.sendEmailTo.details;
      delete audienceDefinition.sendEmailTo.details;
    }

    if (
      get(audienceDefinition, 'sendEmailTo.type.value') === 'customCriteria'
    ) {
      delete audienceDefinition.sendEmailTo.details.campaignIds;
      const list = get(
        audienceDefinition,
        'sendEmailTo.details.customCriteriaList',
        []
      );
      if (list.includes('campaignIds')) {
        const campaignIdsList = [];
        get(
          audienceDefinition,
          'sendEmailTo.details.criteriaCampaignIds',
          []
        ).forEach(item => {
          if (item.value && item.value.trim() !== '') {
            campaignIdsList.push(item);
          }
        });
        audienceDefinition.sendEmailTo.details.criteriaCampaignIds = campaignIdsList;
      }
    }

    return {
      audienceDefinition: audienceDefinition,
      userEmail: userEmail,
      userName: userName,
      status: status
    };
  }

  if (type === 'abTesting') {
    const clonedData = cloneDeep(data);
    const {
      abTesting,
      userEmail,
      userName,
      status,
      emailAttributes
    } = clonedData;
    const testSplitData = get(abTesting, 'abTestSplits');

    if (testSplitData) {
      abTesting.noOfABVariants =
        constant.EMAIL_AB_SPLIT_VARIANT_MAP[testSplitData];
    }

    return {
      emailAttributes,
      abTesting: abTesting,
      userEmail: userEmail,
      userName: userName,
      status: status
    };
  }

  return data;
};

const transformSMSPayloadByAudience = data => {
  const clonedData = cloneDeep(data);
  const { audienceDefinition, userEmail, userName, status } = clonedData;

  if (
    get(audienceDefinition, 'sendSMSTo.type.value') ===
    'consumersLinkedToCampaignIds'
  ) {
    const campaignIds = audienceDefinition.sendSMSTo.details.campaignIds;
    const campaignIdsList = [];
    campaignIds.forEach(campaign => {
      if (campaign.value && campaign.value.trim() !== '') {
        campaignIdsList.push(campaign.value);
      }
    });
    audienceDefinition.sendSMSTo.details.campaignIds = campaignIdsList;
  }

  if (
    get(audienceDefinition, 'sendSMSTo.type.value') ===
    'allConsentedContactableConsumers'
  ) {
    delete audienceDefinition.sendSMSTo.details;
    delete audienceDefinition.sendSMSTo.details;
  }

  if (get(audienceDefinition, 'sendSMSTo.type.value') === 'customCriteria') {
    delete audienceDefinition.sendSMSTo.details.campaignIds;
    const list = get(
      audienceDefinition,
      'sendSMSTo.details.customCriteriaList',
      []
    );
    if (list.includes('campaignIds')) {
      const campaignIdsList = [];
      get(
        audienceDefinition,
        'sendSMSTo.details.criteriaCampaignIds',
        []
      ).forEach(item => {
        if (item.value && item.value.trim() !== '') {
          campaignIdsList.push(item);
        }
      });
      audienceDefinition.sendSMSTo.details.criteriaCampaignIds = campaignIdsList;
    }
  }

  return {
    audienceDefinition: audienceDefinition,
    userEmail: userEmail,
    userName: userName,
    status: status
  };
};

const transformSMSDataForValidation = (data, isReview) => {
  const clonedData = cloneDeep(data);

  let {
    summary = {},
    deliverySchedule = {},
    audienceDefinition = {},
    campaignAttributes,
    // emailCollaborators = {},
    uid,
    designSMS
    // emailAttributes
  } = clonedData;

  const { name, smsContentTheme, smsContentSecondaryTheme } = data || {};
  const { smsOverview, dpcPraReferenceNo, smsDetails } =
    data?.communicationDetail || {};

  summary.name = name;
  summary.smsOverview = smsOverview;
  summary.smsContentTheme = smsContentTheme;
  summary.smsContentSecondaryTheme = smsContentSecondaryTheme;
  summary.dpiaReferenceNo = dpcPraReferenceNo;
  summary.emailCollaborators = clonedData.emailCollaborators;

  const SMSTriggerType = get(deliverySchedule, 'smsTrigger.type');
  if (SMSTriggerType && SMSTriggerType === 'fixedDateAndtime') {
    const date = get(deliverySchedule, 'smsTrigger.details.smsDeliveryDate');
    const time = get(deliverySchedule, 'smsTrigger.details.smsDeliveryTime');
    if (date) {
      deliverySchedule.smsTrigger.details.smsDeliveryDate = moment
        .utc(date)
        .toDate();
    }
    if (time) {
      deliverySchedule.smsTrigger.details.smsDeliveryTime = moment
        .utc(time)
        .toDate();
    }
  }

  if (isReview) {
    if (SMSTriggerType && SMSTriggerType === 'dynamicTrigger') {
      const triggerConditionStartDate = get(
        deliverySchedule,
        'smsTrigger.details.triggerConditionStartDate'
      );
      const triggerConditionEndDate = get(
        deliverySchedule,
        'smsTrigger.details.triggerConditionEndDate'
      );
      if (triggerConditionStartDate) {
        deliverySchedule.smsTrigger.details.triggerConditionStartDate = moment
          .utc(triggerConditionStartDate)
          .format('DD/MM/YYYY');
      }
      if (triggerConditionEndDate) {
        deliverySchedule.smsTrigger.details.triggerConditionEndDate = moment
          .utc(triggerConditionEndDate)
          .format('DD/MM/YYYY');
      }
    }
  }

  const sendSMSToType = get(audienceDefinition, 'sendSMSTo.type.value');
  if (sendSMSToType && sendSMSToType === 'consumersLinkedToCampaignIds') {
    const campaignIds = audienceDefinition.sendSMSTo.details.campaignIds;
    audienceDefinition.sendSMSTo.details.campaignIds = campaignIds.map(
      (campaign, index) => {
        // Why is this needed ?
        return { id: `field_${index + 1}`, value: campaign };
      }
    );
  }

  campaignAttributes = {
    smsAttributes: {
      smsCoupon: clonedData?.smsAttributes?.smsCoupon?.couponApplied,
      smsCouponType: clonedData?.smsAttributes?.smsCoupon?.type,
      shareCouponFileViaKana:
        clonedData?.smsAttributes?.smsCoupon?.shareCouponFileViaKana,
      couponCode: clonedData?.smsAttributes?.smsCoupon?.code,
      kanaTicketNumber: clonedData?.smsAttributes?.smsCoupon?.kanaTicketNumber
    }
  };

  designSMS = {
    smsContent: smsDetails[0]?.smsContent || '',
    optOutText: smsDetails[0]?.optOutText || '',
    smsContentFollowUp: smsDetails[1]?.smsContent || '',
    optOutTextFollowUp: smsDetails[1]?.optOutText || ''
  };

  return {
    summary,
    deliverySchedule,
    audienceDefinition,
    // emailCollaborators,
    uid,
    campaignAttributes,
    designSMS
  };
};

const checkCustomRulesList = (rulesList = [], user, pageName, userGroup) => {
  let flag = true;
  rulesList.forEach(ruleFnc => {
    if (typeof ruleFnc === 'function') {
      if (!ruleFnc(user, pageName, userGroup)) {
        flag = false;
      }
    } else {
      throw new Error('Rule should be a function');
    }
  });
  return flag;
};

function verifyBrandConsentRules(consentConfig, campaignObject) {
  const brandMarketRules =
    consentConfig && consentConfig.brandConsentRules
      ? consentConfig.brandConsentRules
      : [];

  const campaignBrand = campaignObject.brand.toLowerCase();
  const campaignMarket = campaignObject.market.toLowerCase();
  for (const ele of brandMarketRules) {
    const brand = ele.brand && ele.brand.toLowerCase();
    const market = ele.market && ele.market.toLowerCase();

    if (ele.brand && ele.market) {
      if (brand === campaignBrand && market === campaignMarket) {
        return true;
      }
    } else if (ele.brand && !ele.market) {
      if (brand === campaignBrand) {
        return true;
      }
    } else if (ele.market && !ele.brand) {
      if (market === campaignMarket) {
        return true;
      }
    }
  }
  return false;
}

const isConsentFieldEditable = ({
  field,
  defaultValue,
  role = '',
  campaignConfig = {},
  isEpsilonMarket,
  brand = ''
}) => {
  // make the field always editable if user is admin
  if (role === 'admin') {
    return true;
  }

  // make field uneditable for other roles based on field and country
  if (role !== 'admin') {
    // EpsilonMarket (currently only US)
    if (isEpsilonMarket) {
      return typeof campaignConfig.CONSENT_EDITABLE_FLAGS_US[field] !==
        'undefined'
        ? campaignConfig.CONSENT_EDITABLE_FLAGS_US[field]
        : true;
    }

    // return based on flag set in DB
    return typeof campaignConfig.CONSENT_EDITABLE_FLAGS[field] !== 'undefined'
      ? campaignConfig.CONSENT_EDITABLE_FLAGS[field]
      : true;
  }
};

function isBrandEmailSmsConsentVisible(
  campaignConfig,
  countrySelected,
  brandCode
) {
  if (!(campaignConfig && campaignConfig.ENTERPRISE_CONSENT_ONLY)) {
    return true;
  }

  if (
    verifyBrandConsentRules(campaignConfig, {
      market: countrySelected,
      brand: brandCode
    })
  ) {
    return true;
  }

  return false;
}

const checkRouteAccess = (campaign, pageName) => {
  // false = true , true = false
  if (pageName === 'approval' && campaign.status === '6') {
    return false;
  } else if (
    (pageName === 'approval' ||
      pageName === 'masterdata' ||
      pageName === 'promotigo-info') &&
    campaign.status === '1'
  ) {
    return true;
  } else if (pageName === 'masterdata' && campaign.status === '7') {
    return false;
  } else if (pageName === 'promotigo-info' && campaign.status === '12') {
    return false;
  } else if (
    (campaign && campaign.isEdited === false && campaign.status === '3') ||
    campaign.status === '6' ||
    campaign.status === '7' ||
    campaign.status === '12'
  ) {
    return true;
  }
  return false;
};

const checkAccessToEmailBrefing = user => {
  if (!constant.featureFlags.ENABLE_CAMPAIGN_EMAIL) {
    return false;
  }

  if (!constant.featureFlags.ENABLE_EMAIL_PRIVILEGED_ACCESS) {
    return true;
  }

  if (!constant.EMAIL_PRIVILEGED_ACCESS.includes(user.emailId)) {
    return true;
  }

  return false;
};
const getFeedbackDownloadLink = () => {
  const startDate = encodeURIComponent('01/01/2022');
  const endDate = encodeURIComponent(moment().format('DD/MM/YYYY'));
  return `${constant.serviceUrls.FEEDBACKS_URL}?startDate=${startDate}&endDate=${endDate}&action=export`;
};

const getFieldValue = (givenObject, fieldPath, defaultValue) => {
  const pathArray = Array.isArray(fieldPath)
    ? fieldPath
    : fieldPath.split('.').filter(key => key);
  const pathArrayFlat = pathArray; //.flatMap(part => typeof part === 'string' ? part.split('.') : part)

  return (
    pathArrayFlat.reduce((obj, key) => obj && obj[key], givenObject) ||
    defaultValue
  );
};

const getChartData = (data, timeSlice, startDate) => {
  var aggregatedData = {};
  for (var index in data) {
    // Get Moment for data set
    var m = moment(data[index].timestamp);
    var week = moment(m).day(0);
    m = startDate && startDate > week ? startDate : m;
    if (m) {
      var key =
        timeSlice === 'weekly'
          ? week.format('DD MMM YYYY')
          : timeSlice === 'annual'
          ? m.format('YYYY')
          : timeSlice === 'quarter'
          ? m.format('YYYY') + ' Q' + (parseInt(m.month() / 3) + 1)
          : m.format('MMM YYYY');

      aggregatedData[key] = aggregatedData[key]
        ? aggregatedData[key] + data[index].value
        : data[index].value;
    }
  }

  // Generate labels and Data Sets
  var dataSet = [];
  var labels = [];
  for (var label in aggregatedData) {
    labels.push(`${label}`);
    dataSet.push(`${aggregatedData[label]}`);
  }
  return { labels, dataSet };
};

const transformUsageOfQuestiosnData = data => {
  if (Array.isArray(data)) {
    return data.map(elem => {
      if (elem.listId === '5') {
        elem.listItems = elem.listItems.map(item => {
          item.label = constant.CONSENTS_KEY_LABEL_MAP[item.optInType];
          return item;
        });
      }
      return elem;
    });
  }
  return data;
};

const transformToURLQueryString = options => {
  let query = '';
  Object.keys(options).forEach((key, index) => {
    query += `${index !== 0 ? '&' : ''}${key}=${encodeURIComponent(
      options[key]
    )}`;
  });
  return query;
};

const checkForNullOrUndefined = (value, defaultValue) => {
  if (_.isUndefined(value) || _.isNull(value)) {
    return defaultValue;
  }
  return value;
};

const hasAEMFlow = campaign => {
  let campaignWebsiteType =
    campaign && campaign.websiteType && campaign.websiteType.name
      ? campaign.websiteType.name.toLowerCase()
      : '';
  let isOtherWebsiteType =
    campaignWebsiteType === 'other' ||
    campaignWebsiteType === 'liberty' ||
    campaignWebsiteType === 'chatbot'
      ? true
      : false;
  return (
    (isOtherWebsiteType && campaign.autogenerate) ||
    !campaign.isPlatformAgnostic
  );
};
function filterToQuery(filter) {
  let query = '?';
  for (const key in filter) {
    if (filter.hasOwnProperty(key)) {
      if (Array.isArray(filter[key])) {
        // eslint-disable-next-line no-loop-func
        filter[key].forEach(val => {
          query += key + '=' + val + '&';
        });
      } else {
        query += key + '=' + filter[key] + '&';
      }
    }
  }
  return query.slice(0, -1);
}

const getEmailCampStepProgressDate = (status, step, campaignHistory) => {
  if (status === 'is-done') {
    if (
      campaignHistory &&
      Array.isArray(campaignHistory) &&
      !isEmpty(campaignHistory)
    ) {
      let event = constant.EMAIL_AMPAIGN_HISTORY_PROGRESS_STEP_MAP[step.id];
      let eventLog =
        campaignHistory && campaignHistory.find(elem => elem.event === event);
      let eventDate = eventLog
        ? moment(eventLog.eventDate).format('DD-MMM-YYYY')
        : moment().format('DD-MMM-YYYY');
      return eventDate;
    }
    return moment().format('DD-MMM-YYYY');
  } else if (status === 'is-now') {
    return 'Now';
  } else {
    return 'Later';
  }
};

const getEmailStepProgressStatus = (index, progressIndex, totalSteps, step) => {
  function checkIfSumbmited() {
    return progressIndex === 0 || index === totalSteps - 1
      ? index === progressIndex + 1
      : false;
  }

  function checkIfLive() {
    return index === progressIndex
      ? step.status.includes(constant.EMAIL_STATUS.LIVE) ||
          step.status.includes(constant.EMAIL_STATUS.SCHEDULED)
      : false;
  }
  // || progressIndex === totalSteps - 1
  if (index < progressIndex || index === 0 || checkIfLive()) {
    return 'is-done';
  } else if (index === progressIndex || checkIfSumbmited()) {
    return 'is-now';
  } else {
    return 'is-later';
  }
};

const getEmailCampaignStatusSteps = (campaign = {}, campaignHistory = []) => {
  let progressSteps = [];
  let progresStepList = constant
    .EMAIL_CAMPAIGN_TIMELINE_STEPS()
    .filter(elem => {
      if (
        !constant.featureFlags.EMAIL_APPROVAL_REQUIRED &&
        elem.status.includes(constant.EMAIL_STATUS.AWAITING_FOR_APPROVAL) &&
        campaign.status !== constant.EMAIL_STATUS.AWAITING_FOR_APPROVAL
      ) {
        return false;
      }
      if (
        !constant.featureFlags.EMAIL_EXECUTION_REQUIRED &&
        (elem.status.includes(constant.EMAIL_STATUS.AWAITING_SETUP) ||
          elem.status.includes(constant.EMAIL_STATUS.SCHEDULED) ||
          elem.status.includes(constant.EMAIL_STATUS.LIVE)) &&
        ![
          constant.EMAIL_STATUS.AWAITING_SETUP,
          constant.EMAIL_STATUS.SCHEDULED,
          constant.EMAIL_STATUS.LIVE
        ].includes(campaign.status)
      ) {
        return false;
      }
      return true;
    });
  let totalSteps = progresStepList.length;
  let progressIndex = progresStepList.findIndex(elem =>
    elem.status.includes(campaign.status)
  );
  progresStepList.forEach((step, index) => {
    let status = getEmailStepProgressStatus(
      index,
      progressIndex,
      totalSteps,
      step
    );
    let title = step.stepName;
    progressSteps.push({
      title: title,
      subTitle: step.stepDescription,
      completedAt: getEmailCampStepProgressDate(status, step, campaignHistory),
      status: status,
      id: step.id
    });
  });

  return progressSteps;
};

const checkIsEmailAdminOrACS = user => {
  const accessTo = user?.accessTo;
  const isEmailAdminOrACS =
    user?.group === 'admin' ||
    (accessTo &&
      accessTo.some(data => !!data.emailCampaign) &&
      user?.group === 'acs');
  return isEmailAdminOrACS;
};

const checkIfUserIsRequestorOrApprover = user => {
  return ['requestor', 'approver'].includes(user?.group);
};

const checkEmailRouteAccess = (campaign, pageName, userGroup) => {
  /*** this below method is use for email & sms journey */
  // false = true , true = false
  if (pageName === 'approval' && campaign.status === '3') {
    return false;
  } else if (
    pageName === 'approval' &&
    (campaign.status === '1' ||
      campaign.status === '5' ||
      campaign.status === '8')
  ) {
    return true;
  } else if (
    pageName === 'execution' &&
    (campaign.status === '1' || campaign.status === '5')
  ) {
    return true;
  } else if (
    pageName === 'execution' &&
    (campaign.status === '4' ||
      campaign.status === '6' ||
      campaign.status === '7')
  ) {
    return false;
  } else if (
    pageName === 'execution' &&
    ['acs', 'admin'].includes(userGroup) &&
    campaign.status === '8' &&
    campaign.nmiAssignedToRequestor === true
  ) {
    return true;
  } else if (
    pageName === 'execution' &&
    ['acs', 'admin'].includes(userGroup) &&
    campaign.status === '10'
  ) {
    return true;
  } else if (
    pageName === 'email-submit' &&
    (campaign.status === '2' || campaign.status === '3')
  ) {
    return false;
  } else if (
    campaign.status === '2' ||
    campaign.status === '3' ||
    campaign.status === '4' ||
    campaign.status === '6' ||
    campaign.status === '7'
  ) {
    return true;
  }
  return false;
};

const checkApproverCampaignEmailAccess = (userData, role, window) => {
  if (role === 'approver') {
    let isformCampaign = false;
    let isEmailCampaign = false;
    let isSmsCampaign = false;
    if (userData && userData.accessTo) {
      for (const ele of userData.accessTo) {
        if (ele.emailCampaign) {
          isEmailCampaign = true;
        }
        if (ele.formCampaign) {
          isformCampaign = true;
        }
        if (ele.smsCampaign) {
          isSmsCampaign = true;
        }
      }
    }
    if (
      ((window.location.pathname.includes('/new-email-campaign') ||
        window.location.pathname.includes('/emailbriefing')) &&
        (window.location.pathname.includes('/approval') ||
          window.location.pathname.includes('/execution')) &&
        !isEmailCampaign) ||
      (window.location.pathname.includes('/campaign/') &&
        window.location.pathname.includes('/approval') &&
        !isformCampaign) ||
      (window.location.pathname.includes('/communications/') &&
        window.location.pathname.includes('/approval') &&
        !isSmsCampaign)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getCampaignTypeText = ({ campaignType, incentiveType }) => {
  const {
    INCENTIVE,
    PRODUCT_SAMPLE,
    COMPITITIONS,
    INCENTIVE_COMPITITIONS,
    INCENTIVE_SAMPLE
  } = constant.campaignRequestTypes;

  const isIncentive = campaignType === INCENTIVE;
  const isSamples = incentiveType === PRODUCT_SAMPLE;
  const isCompetitions = incentiveType === COMPITITIONS;

  const isIncentiveCompetitions = isIncentive && isCompetitions;
  const isIncentiveSamples = isIncentive && isSamples;

  if (isIncentiveCompetitions) {
    return INCENTIVE_COMPITITIONS;
  }

  if (isIncentiveSamples) {
    return INCENTIVE_SAMPLE;
  }
};

const checkEmailCampaignEditRights = (emailCampaign, user) => {
  if (!emailCampaign && !user) {
    return false;
  } else if (!emailCampaign && user) {
    return true;
  }

  if (['acs', 'admin'].includes(user.group)) {
    return true;
  } else {
    const emailCollaborators = get(emailCampaign, 'emailCollaborators', {});
    const collaboratorsList = get(
      emailCampaign,
      'emailCollaborators.collaborators',
      []
    );
    const collabeEmailList = _.map(collaboratorsList, 'value');

    if (emailCollaborators.type === 'everyone') {
      return true;
    } else if (emailCollaborators.type === 'requestor') {
      return emailCampaign.requestersEmail === user.emailId;
    } else {
      if (
        collabeEmailList.includes(user.emailId) ||
        (emailCampaign && emailCampaign.requestersEmail === user.emailId)
      ) {
        return true;
      }
    }

    return false;
  }
};

const checkSMSCampaignEditRights = (campaign, user) => {
  if (!campaign && !user) {
    return false;
  } else if (!campaign && user) {
    return true;
  }
  if (['acs', 'admin'].includes(user.group)) {
    return true;
  } else {
    const emailCollaborators = get(campaign, 'emailCollaborators', {});
    const collaboratorsList = get(
      campaign,
      'emailCollaborators.collaborators',
      []
    );
    const collabeEmailList = _.map(collaboratorsList, 'value');

    if (emailCollaborators.type === 'everyone') {
      return true;
    } else if (emailCollaborators.type === 'requestor') {
      return campaign.requestorsEmail === user.emailId;
    } else {
      if (
        collabeEmailList.includes(user.emailId) ||
        (campaign && campaign.requestorsEmail === user.emailId)
      ) {
        return true;
      }
    }
    return false;
  }
};

const checkAccessToEmailBrefingV2 = (user, emailCampaign, pageName) => {
  if (
    window.location.pathname.includes('/new-email-campaign') ||
    window.location.pathname.includes('/emailbriefing') ||
    window.location.pathname.includes('/email-dashboard')
  ) {
    if (!constant.featureFlags.ENABLE_CAMPAIGN_EMAIL) {
      return true;
    }

    if (constant.featureFlags.ENABLE_EMAIL_PRIVILEGED_ACCESS) {
      if (constant.EMAIL_PRIVILEGED_ACCESS.includes(user.emailId)) {
        return false;
      } else {
        return true;
      }
    }

    if (
      !window.location.pathname.includes('/email-dashboard') &&
      !window.location.pathname.includes('/new-email-campaign') &&
      !['email-overview', 'approval', 'execution'].includes(pageName)
    ) {
      return !checkEmailCampaignEditRights(emailCampaign, user);
    }

    return false;
  }
};

const addMetaLangToTemplate = (templateObject, emailBreifing) => {
  const newTemplateObj = { ...templateObject };
  // const countryCode = get(emailBreifing, 'country.code', null)
  const langaugeCode = get(emailBreifing, 'language.code', null);
  if (langaugeCode) {
    const langCode = `${langaugeCode.toLowerCase()}`;
    set(newTemplateObj, 'page.head.meta.lang', langCode);
  }
  return newTemplateObj;
};

const checkEmailCampaignSendTestRights = (emailCampaign, user) => {
  if (!emailCampaign && !user) {
    return false;
  } else if (!emailCampaign && user) {
    return true;
  }

  if (['acs', 'admin', 'approver'].includes(user.group)) {
    return true;
  } else {
    const emailCollaborators = get(emailCampaign, 'emailCollaborators', {});
    const collaboratorsList = get(
      emailCampaign,
      'emailCollaborators.collaborators',
      []
    );
    const collabeEmailList = _.map(collaboratorsList, 'value');

    if (emailCollaborators.type === 'everyone') {
      return true;
    } else if (emailCollaborators.type === 'requestor') {
      return emailCampaign.requestersEmail === user.emailId;
    } else {
      if (
        collabeEmailList.includes(user.emailId) ||
        (emailCampaign && emailCampaign.requestersEmail === user.emailId)
      ) {
        return true;
      }
    }

    return false;
  }
};

const getAudienceSendEmailTolabel = data => {
  const type = get(data, 'audienceDefinition.sendEmailTo.type.value');
  if (type === 'allConsentedContactableConsumers') {
    return data.isCrossBrand && data.summaryCard
      ? 'All consumers signed up to atleast one of the selected brands'
      : data.isCrossBrand && !data.summaryCard
      ? 'All consumers who signed up to atleast one of the selected brands in the market'
      : `All consumers who signed up to ${get(data, 'audienceBrand')} in ${get(
          data,
          'country.name'
        )}`;
  }
  return get(data, 'audienceDefinition.sendEmailTo.type.label');
};

const formatDateTime = (dateTime, format) => {
  if (dateTime) {
    const momentParsedDate = moment.utc(dateTime);
    return momentParsedDate.isValid() ? momentParsedDate.format(format) : '';
  }
  return '';
};

const getDeliveryCondition = (
  deliverySchedule = {},
  isABTestOnSendTime = false
) => {
  if (deliverySchedule?.emailTrigger?.type === 'fixedDateAndtime') {
    if (isABTestOnSendTime) {
      const variantADateTime = _.get(
        deliverySchedule,
        'emailTrigger.details.emailDeliveryDateVariantA'
      );
      const variantBDateTime = _.get(
        deliverySchedule,
        'emailTrigger.details.emailDeliveryDateVariantB'
      );
      const variantATime = _.get(
        deliverySchedule,
        'emailTrigger.details.emailDeliveryTimeVariantA'
      );
      const variantBTime = _.get(
        deliverySchedule,
        'emailTrigger.details.emailDeliveryTimeVariantB'
      );

      return variantADateTime && variantBDateTime
        ? [
            `${formatDateTime(variantADateTime, 'DD MMM YYYY')},
            ${formatDateTime(variantATime, 'hh:mm a')} UTC`,
            `${formatDateTime(variantBDateTime, 'DD MMM YYYY')},
            ${formatDateTime(variantBTime, 'hh:mm a')} UTC`
          ]
        : [];
    }

    const dateTime = _.get(
      deliverySchedule,
      'emailTrigger.details.emailDeliveryDate'
    );
    const time = _.get(
      deliverySchedule,
      'emailTrigger.details.emailDeliveryTime'
    );
    return dateTime
      ? [
          `${formatDateTime(dateTime, 'DD MMM YYYY')},
          ${formatDateTime(time, 'hh:mm a')} UTC`
        ]
      : [];
  }
  const emailTrigger = deliverySchedule?.emailTrigger?.details;
  const otherTrigger = emailTrigger?.triggerCondition?.value === 'other';
  const isFixedDateInBirtdayMonth =
    deliverySchedule?.emailTrigger?.details?.triggerCondition?.value ===
    'fixedDateInBirthdayMonth';
  const label = emailTrigger?.triggerCondition?.label;

  let displayLabel;

  if (otherTrigger) {
    displayLabel = (
      <span
        data-for="trigger-tooltip"
        data-tip={emailTrigger?.otherTriggerDetails}
      >{`Trigger ${
        emailTrigger?.otherTriggerDetails
          ? ` - ${emailTrigger?.otherTriggerDetails}`
          : ''
      }`}</span>
    );
  } else if (isFixedDateInBirtdayMonth) {
    const birthdate =
      deliverySchedule?.emailTrigger?.details?.triggerConditionBirthdayDate
        ?.value;
    const value = birthdate
      ? `Trigger fixed date - Day ${deliverySchedule?.emailTrigger?.details?.triggerConditionBirthdayDate?.value} of the birthday month`
      : `Trigger ${_.toLower(label)}`;
    displayLabel = (
      <span data-for="trigger-tooltip" data-tip={value}>
        {value}
      </span>
    );
  } else {
    displayLabel = (
      <span data-for="trigger-tooltip" data-tip={`Trigger ${_.toLower(label)}`}>
        {`Trigger ${_.toLower(label)}`}
      </span>
    );
  }

  const result = [
    label ? (
      <>
        {displayLabel}
        <ReactTooltip
          id="trigger-tooltip"
          place="bottom"
          type="info"
          multiline={true}
          className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
        />
      </>
    ) : (
      'Not specified'
    )
  ];

  if (!isFixedDateInBirtdayMonth) {
    const isAlwaysOn = emailTrigger?.triggerConditionAlwaysOn;

    if (emailTrigger?.triggerConditionStartDate) {
      const startDate = moment(emailTrigger?.triggerConditionStartDate).format(
        'DD MMM YYYY'
      );

      if (isAlwaysOn) {
        result.push(`${startDate} - Always on`);
        return result;
      } else {
        const endDate = moment(emailTrigger?.triggerConditionEndDate).format(
          'DD MMM YYYY'
        );
        result.push(`${startDate} - ${endDate}`);
      }
    }
  }

  return result;
};

const getSMSDeliveryCondition = (
  deliverySchedule = {},
  isABTestOnSendTime = false
) => {
  if (deliverySchedule?.smsTrigger?.type === 'fixedDateAndtime') {
    if (isABTestOnSendTime) {
      const variantADateTime = _.get(
        deliverySchedule,
        'smsTrigger.details.smsDeliveryDateVariantA'
      );
      const variantBDateTime = _.get(
        deliverySchedule,
        'smsTrigger.details.smsDeliveryDateVariantB'
      );
      const variantATime = _.get(
        deliverySchedule,
        'smsTrigger.details.smsDeliveryTimeVariantA'
      );
      const variantBTime = _.get(
        deliverySchedule,
        'smsTrigger.details.smsDeliveryTimeVariantB'
      );

      return variantADateTime && variantBDateTime
        ? [
            `${formatDateTime(variantADateTime, 'DD MMM YYYY')},
            ${formatDateTime(variantATime, 'hh:mm a')} UTC`,
            `${formatDateTime(variantBDateTime, 'DD MMM YYYY')},
            ${formatDateTime(variantBTime, 'hh:mm a')} UTC`
          ]
        : [];
    }

    const dateTime = _.get(
      deliverySchedule,
      'smsTrigger.details.smsDeliveryDate'
    );
    const time = _.get(deliverySchedule, 'smsTrigger.details.smsDeliveryTime');
    return dateTime
      ? [
          `${formatDateTime(dateTime, 'DD MMM YYYY')},
          ${formatDateTime(time, 'hh:mm a')} UTC`
        ]
      : [];
  }
  const smsTrigger = deliverySchedule?.smsTrigger?.details;
  const otherTrigger = smsTrigger?.triggerCondition?.value === 'other';
  const isFixedDateInBirtdayMonth =
    deliverySchedule?.smsTrigger?.details?.triggerCondition?.value ===
    'fixedDateInBirthdayMonth';
  const label = smsTrigger?.triggerCondition?.label;

  let displayLabel;

  if (otherTrigger) {
    displayLabel = (
      <span
        data-for="trigger-tooltip"
        data-tip={smsTrigger?.otherTriggerDetails}
      >{`Trigger ${
        smsTrigger?.otherTriggerDetails
          ? ` - ${smsTrigger?.otherTriggerDetails}`
          : ''
      }`}</span>
    );
  } else if (isFixedDateInBirtdayMonth) {
    const birthdate =
      deliverySchedule?.smsTrigger?.details?.triggerConditionBirthdayDate
        ?.value;
    const value = birthdate
      ? `Trigger fixed date - Day ${deliverySchedule?.smsTrigger?.details?.triggerConditionBirthdayDate?.value} of the birthday month`
      : `Trigger ${_.toLower(label)}`;
    displayLabel = (
      <span data-for="trigger-tooltip" data-tip={value}>
        {value}
      </span>
    );
  } else {
    displayLabel = (
      <span data-for="trigger-tooltip" data-tip={`Trigger ${_.toLower(label)}`}>
        {`Trigger ${_.toLower(label)}`}
      </span>
    );
  }

  const result = [
    label ? (
      <>
        {displayLabel}
        <ReactTooltip
          id="trigger-tooltip"
          place="bottom"
          type="info"
          multiline={true}
          className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
        />
      </>
    ) : (
      'Not specified'
    )
  ];

  if (!isFixedDateInBirtdayMonth) {
    const isAlwaysOn = smsTrigger?.triggerConditionAlwaysOn;

    if (smsTrigger?.triggerConditionStartDate) {
      const startDate = moment(smsTrigger?.triggerConditionStartDate).format(
        'DD MMM YYYY'
      );

      if (isAlwaysOn) {
        result.push(`${startDate} - Always on`);
        return result;
      } else {
        const endDate = moment(smsTrigger?.triggerConditionEndDate).format(
          'DD MMM YYYY'
        );
        result.push(`${startDate} - ${endDate}`);
      }
    }
  }

  return result;
};

const addToArray = (givenArray, elem) => {
  const newList = [...givenArray];
  if (!newList.includes(elem)) {
    newList.push(elem);
  }
  return newList;
};
/**
 * Returns an object which contains information about which variants were assigned to which rows.
 * @param {object} templateObj - The template json object from BEE editor.
 */
const getRowAndVariantComboInfo = templateObj => {
  const info = {
    variantA: { isAssigned: false, rowId: [] },
    variantB: { isAssigned: false, rowId: [] },
    variantC: { isAssigned: false, rowId: [] }
  };
  const rows = get(templateObj, 'page.rows', null);
  if (rows) {
    rows.forEach(row => {
      const displayCondition = row.container.displayCondition;
      if (displayCondition) {
        if (displayCondition.id === 'variantA') {
          info.variantA = {
            isAssigned: true,
            rowId: addToArray(info.variantA.rowId, row.uuid)
          };
        }
        if (displayCondition.id === 'variantB') {
          info.variantB = {
            isAssigned: true,
            rowId: addToArray(info.variantB.rowId, row.uuid)
          };
        }
        if (displayCondition.id === 'variantC') {
          info.variantC = {
            isAssigned: true,
            rowId: addToArray(info.variantC.rowId, row.uuid)
          };
        }
      }
    });
  }
  return info;
};

const hasEmailContentABTest = emailBrief => {
  const performABTests = get(emailBrief, 'abTesting.performABTests', false);
  const abTestElements = get(emailBrief, 'abTesting.abTestElements', '');
  return performABTests && abTestElements === 'emailContent' ? true : false;
};

const hasSubjectLineABTest = emailBrief => {
  const performABTests = get(emailBrief, 'abTesting.performABTests', false);
  const abTestElements = get(emailBrief, 'abTesting.abTestElements', '');
  return performABTests && abTestElements === 'subjectLine' ? true : false;
};

const applyDefaultStyles = (brandDefaults, template, brandCode) => {
  const { buttons, links, paragraph, title } = brandDefaults;
  const oldStyles = {
    links: {
      color: ''
    }
  };

  const newTemplateRows = template?.page?.rows?.map(row => {
    row.columns.map(column => {
      column.modules.map(module => {
        let keys = Object.keys(module.descriptor);
        keys = keys.filter(key =>
          ['heading', 'paragraph', 'button', 'menuItemsList'].includes(key)
        );

        switch (keys[0]) {
          case 'heading':
            // const titleStyle = module.descriptor.heading.style
            // oldStyles.title = {
            //   ...titleStyle,
            //   color: titleStyle.color,
            //   fontFamily: {fontFamily: titleStyle['font-family']},
            // }

            // if(titleStyle['link-color']) {
            //   oldStyles.links.color = titleStyle['link-color']
            // }

            module.descriptor = {
              ...module.descriptor,
              heading: {
                ...module.descriptor.heading,
                style: {
                  ...module.descriptor.heading.style,
                  color: title.color,
                  'font-family': title?.fontFamily?.fontFamily || 'Arial',
                  'link-color': links.color
                }
              }
            };
            break;
          case 'paragraph':
            // const paragraphStyle = module.descriptor.paragraph.style
            // oldStyles.paragraph = {
            //   ...paragraphStyle,
            //   color: paragraphStyle.color,
            //   fontSize: paragraphStyle['font-size'].replace('px', ''),
            //   fontFamily: {fontFamily: paragraphStyle['font-family']},
            // }

            // if(paragraphStyle.linkColor) {
            //   oldStyles.links.color = paragraphStyle.linkColor
            // }

            module.descriptor = {
              ...module.descriptor,
              paragraph: {
                ...module.descriptor.paragraph,
                computedStyle: {
                  ...module.descriptor.paragraph.computedStyle,
                  linkColor: links.color
                },
                style: {
                  ...module.descriptor.paragraph.style,
                  color: paragraph.color,
                  'font-size': `${paragraph.fontSize}px`,
                  'font-family': paragraph?.fontFamily?.fontFamily || 'Arial'
                }
              }
            };
            break;
          case 'button':
            // const buttonStyle = module.descriptor.button.style
            // oldStyles.buttons = {
            //   ...buttonStyle,
            //   color: buttonStyle.color,
            //   backgroundColor: buttonStyle['background-color'],
            //   borderRadius: buttonStyle['border-radius'].replace('px', ''),
            //   fontFamily: {fontFamily: buttonStyle['font-family']},
            //   fontSize: buttonStyle['font-size'].replace('px', ''),
            //   fontWeight: buttonStyle['font-weight']
            // }

            module.descriptor = {
              ...module.descriptor,
              button: {
                ...module.descriptor.button,
                style: {
                  ...module.descriptor.button.style,
                  color: buttons.color || '#fff',
                  'background-color': buttons.backgroundColor || '#007dbb',
                  'border-radius': `${buttons.borderRadius}px`,
                  'font-family': buttons?.fontFamily?.fontFamily || 'Arial',
                  'font-size': `${buttons.fontSize}px`,
                  'font-weight': buttons.fontWeight
                }
              }
            };
          case 'menuItemsList':
            module.descriptor = {
              ...module.descriptor,
              computedStyle: {
                ...module.descriptor.computedStyle,
                linkColor: links.color
              }
            };
            break;
        }
        return module;
      });
      return column;
    });
    return row;
  });

  const newTemplate = {
    ...template,
    page: {
      ...template.page,
      rows: newTemplateRows
    }
  };

  // localStorage.setItem(`styles-${brandCode}`, JSON.stringify(oldStyles))

  return newTemplate;
};

let isUnbundledConsentAndMarketCheck = (config, countryCode) => {
  if (config && config.ENABLE_UNBUNDLED_CONSENT) {
    if (
      config &&
      config.UNBUNDLED_CONSENT_COUNTRY_CODE &&
      config.UNBUNDLED_CONSENT_COUNTRY_CODE.length &&
      countryCode
    ) {
      const market = config.UNBUNDLED_CONSENT_COUNTRY_CODE.find(
        e => e.code === countryCode
      );
      if (market) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const shouldRedirectToExecution = ele => {
  if (
    [
      constant.EMAIL_STATUS.AWAITING_SETUP,
      constant.EMAIL_STATUS.SCHEDULED
    ].includes(ele.status)
  ) {
    return true;
  } else if (ele.status === constant.EMAIL_STATUS.LIVE) {
    const isAbTestEnabled = get(ele, 'abTesting.performABTests');
    return isAbTestEnabled || false;
  } else {
    return false;
  }
};

const getEmailScheduleTriggerOptions = (type, defaultValue) => {
  let returnValue;

  switch (type) {
    case 'welcomeEmail':
      returnValue = constant.WELCOME_LIST;
      break;

    case 'newsletter':
      returnValue = constant.NEWSLETTER_LIST;
      break;

    case 'coreinfo':
      returnValue = constant.COREINFO_LIST;
      break;

    case 'birthdayEmail':
      returnValue = constant.BIRTHDAY_LIST;
      break;

    case 'otherMarketingEmails':
      returnValue = constant.OTHER_LIST;
      break;

    default:
      returnValue = constant.OTHER_LIST;
      break;
  }

  return {
    ...returnValue,
    defaultOption:
      returnValue.options.find(o => o.value === defaultValue) ||
      returnValue.defaultOption
  };
};

const getSMSScheduleTriggerOptions = (type, defaultValue) => {
  let returnValue;

  switch (type) {
    case 'welcomeSMS':
      returnValue = constant.WELCOME_LIST;
      break;

    case 'newsletter':
      returnValue = constant.NEWSLETTER_LIST;
      break;

    case 'coreinfo':
      returnValue = constant.COREINFO_LIST;
      break;

    case 'birthdaySMS':
      returnValue = constant.BIRTHDAY_LIST;
      break;

    case 'generalSMS':
      returnValue = constant.OTHER_LIST;
      break;

    default:
      returnValue = constant.OTHER_LIST;
      break;
  }

  return {
    ...returnValue,
    defaultOption:
      returnValue.options.find(o => o.value === defaultValue) ||
      returnValue.defaultOption
  };
};

const getABTestReviewPageErrorMesg = ({
  isAbTestContentError,
  isAbTestSubjectError
}) => {
  const errorElement = isAbTestContentError ? 'content' : 'subject line';
  const elementToConfiqure = isAbTestContentError ? 'content' : 'subject';
  return `You have turned ON an AB test on email ${errorElement} but have not configured all ${elementToConfiqure} variations. Please go back to create variants`;
};

const isQnAAvailableInCampaign = campaign => {
  let questions =
    campaign.campaignDetail &&
    campaign.campaignDetail.fields &&
    campaign.campaignDetail.fields.length > 0
      ? campaign.campaignDetail.fields.filter(
          e => e.dataType === 'questionAnswerPair'
        )
      : [];

  if (questions && questions.length) {
    return true;
  } else {
    return false;
  }
};
const addWatermarkInTemplateImage = () => {
  return `<div style="text-align: center;padding-bottom: 30px;"><br><div style="display: inline-block;vertical-align: middle;margin-right: 10px;"><img src="https://i.postimg.cc/zBQm5TyN/CW-logo-2-70x70.png" alt="Campaign Wizard logo" style="height:50px;width:50px"></div><div style="display: inline-block;font-family: 'Open Sans';">Email created with Campaign Wizard</div></div>`;
};
const getBlobFromBuffer = buffer => {
  const bufferImage = Buffer.from(buffer);
  const uint8Array = new Uint8Array(bufferImage);
  const blob = new Blob([uint8Array], { type: 'image/jpg' });
  return blob;
};

const imageURLComponents = imageUrl => {
  const url = imageUrl?.replace(/[()']/g, '').replace('url', '');
  const imageID = url?.split('/')?.pop();

  let extn;
  let queryString = null;
  const query = {};

  let [id, extnAndQuery] = imageID?.split('.');

  if (extnAndQuery?.split('?').length > 1) {
    [extn, queryString] = extnAndQuery?.split('?');

    queryString = queryString.replace(/im=/g, '');
    console.log(queryString);

    for (let i = 0; i < queryString?.split(';')?.length; i++) {
      const segment = queryString?.split(';')[i];
      console.log(segment);

      const [name, value] = segment?.split('=');
      switch (name) {
        case 'Resize':
          query.width = value?.split(',')[0];
          query.height = value?.split(',')[1];
          break;
        case 'Blur':
          query.blur = value;
      }
    }
  } else {
    extn = extnAndQuery;
  }

  return {
    id,
    extn,
    query
  };
};

const maskData = data => `${data[0]}${'*'.repeat(data.length - 1)}`;

const userHasCouponCodePermission = (
  requester,
  currentUser,
  emailCollaborators
) => {
  const collaborators = [];
  const { email, group } = currentUser;

  if (emailCollaborators.type === 'requestorAndSelectedUsers') {
    collaborators.push(
      ...emailCollaborators.collaborators.map(item =>
        item?.value?.toUpperCase()
      )
    );
  }

  return (
    email === requester ||
    constant.EMAIL_STATIC_COUPON_ACCESS_ROLES.includes(group) ||
    collaborators.includes(email.toUpperCase())
  );
};

const getHeaderNavMenuItems = user => {
  let toolsMenu = [];
  if (user.group === 'admin') {
    toolsMenu.push(
      {
        title: 'User management',
        link: '/campaign-wizard/user',
        id: 'userManagement'
      },
      {
        title: 'Brand management',
        link: '/campaign-wizard/brand-management',
        id: 'brandManagement'
      },
      {
        title: 'Reports',
        link: '/campaign-wizard/report-mgmt-v2',
        id: 'toolReports'
      }
    );
  } else if (user.group === 'acs') {
    toolsMenu.push(
      {
        title: 'Brand management',
        link: '/campaign-wizard/brand-management',
        id: 'brandManagement'
      },
      {
        title: 'Campaign wizard approvers',
        link: '/campaign-wizard/approvers',
        id: 'campaignWizardApprovers'
      }
    );
  } else {
    toolsMenu.push({
      title: 'Campaign wizard approvers',
      link: '/campaign-wizard/approvers',
      id: 'campaignWizardApprovers'
    });
  }
  return [
    {
      title: 'Sign up',
      id: 'signUpMenu',
      dropDownItems: [
        {
          title: 'Create sign up request',
          link: '/campaign-wizard/newcampaign',
          id: 'newCampaign'
        },
        {
          title: 'Sign up dashboard',
          link: '/campaign-wizard/dashboard',
          id: 'dashboard'
        },
        {
          title: 'Data enrichment Q&As',
          link: '/campaign-wizard/consumerdataenrichment',
          id: 'consumerDataEnrichment'
        },
        {
          title: 'Reports',
          link: '/campaign-wizard/report-mgmt-v2',
          id: 'reports'
        }
      ]
    },
    {
      title: 'SMS',
      id: 'smsMenu',
      dropDownItems: [
        {
          title: 'Create SMS campaign',
          link: '/campaign-wizard/newcommunications',
          id: 'newSmsCampaign'
        },
        {
          title: 'SMS campaigns dashboard',
          link: '/campaign-wizard/smsdashboard',
          id: 'smsDashboard'
        }
      ]
    },
    {
      title: 'Email',
      id: 'emailMenu',
      dropDownItems: [
        {
          title: 'Configure brand styling',
          link: '/campaign-wizard/brand-style',
          id: 'configureBrandStyles'
        },
        {
          title: 'Create email campaign',
          link: '/campaign-wizard/new-email-campaign',
          id: 'newEmailCampaign'
        },
        {
          title: 'Email campaigns dashboard',
          link: '/campaign-wizard/email-dashboard',
          id: 'emailDashboard'
        },
        {
          title: 'Saved email templates',
          link: '/campaign-wizard/emailbriefing/email-collection',
          id: 'savedEmailTemplates'
        },
        {
          title: 'Email tutorials',
          link:
            'https://unilever.sharepoint.com/:f:/r/sites/DMCHub/Shared%20Documents/10.%20Playbooks,%20Guides%20%26%20Demos/06.%20121%20Marketing/Campaign%20Wizard/CW%20Email%20Briefing%20Tool/CW_Email_Tutorials?csf=1&web=1&e=tdHrGp',
          id: 'EmailTutorials',
          showLinkIcon: true
        }
      ]
    },
    {
      title: 'Tools',
      id: 'tootsMenu',
      dropDownItems: toolsMenu
    }
  ];
};

const getSMSPreviewData = data => {
  let format = [];
  if (data.smsContent1) {
    let text = data.smsContent1;
    let date = null;
    //data.broadcastDate1
    if (data.broadcastDate1 && data.broadcastTime1) {
      date = moment(
        `${data.broadcastDate1} ${data.broadcastTime1}`,
        'DD/MM/YYYY h:mm A'
      ).format('ddd, D MMM [at] HH:mm');
    }
    format.push({ date: date, text: text, optOutText: data.optOutText });
  }
  if (data.smsContent2) {
    let text = data.smsContent2;
    let date = null;
    //data.broadcastDate1
    if (data.broadcastDate2 && data.broadcastTime2) {
      date = moment(
        `${data.broadcastDate2} ${data.broadcastTime2}`,
        'DD/MM/YYYY h:mm A'
      ).format('ddd, D MMM [at] HH:mm');
    }
    format.push({ date: date, text: text, optOutText: data.optOutText });
  }
  return format;
};

const getSMSReviewData = (smsCampaignDetails, previewDates = {}) => {
  return get(smsCampaignDetails, 'communicationDetail.smsDetails', []).map(
    (item, index) => {
      const day =
        item.broadcastDate || previewDates[`broadcastDate${index + 1}`];
      const time =
        item.broadcastTime || previewDates[`broadcastTime${index + 1}`];
      const dateTime =
        day && time
          ? moment
              .utc(`${day} ${time}`, 'DD/MM/YYYY h:mm A')
              .format('ddd, D MMM [at] h:mm a')
          : '';
      return {
        date: dateTime,
        text: item.smsContent || '',
        optOutText: item.optOutText || '',
        shortURL: get(item, 'URL.[0].shortURL', ''),
        fullURL: get(item, 'URL.[0].fullURL', '')
      };
    }
  );
};

const getSMSAudienceOptions = (brand, country, campaignConfig) =>
  constant.SMS_AUDIENCE_OPTIONS.map(({ label, value }) => ({
    label: stringReplaceAll(label, [brand, country, campaignConfig]),
    value
  }));

const transformSMSPayloadBySchedule = data => {
  const clonedData = cloneDeep(data);
  const {
    deliverySchedule,
    userEmail,
    userName,
    status,
    requestInfoText,
    requestInfoAnswerText,
    nmiAssignedToRequestor,
    shareCostEstimate,
    acsCostFileFormData,
    shortURL,
    isEdited
  } = clonedData;
  const additionalData = {};
  if (get(deliverySchedule, 'smsTrigger.type') === 'fixedDateAndtime') {
    const date = deliverySchedule.smsTrigger.details.smsDeliveryDate;
    const time = deliverySchedule.smsTrigger.details.smsDeliveryTime;
    if (date) {
      deliverySchedule.smsTrigger.details.smsDeliveryDate = moment
        .utc(date)
        .toDate();
    }
    if (time) {
      deliverySchedule.smsTrigger.details.smsDeliveryTime = moment
        .utc(time)
        .toDate();
    }
    delete deliverySchedule.smsTrigger.details.triggerCondition;
  }

  if (get(deliverySchedule, 'smsTrigger.type') === 'dynamicTrigger') {
    const startDate =
      deliverySchedule.smsTrigger.details.triggerConditionStartDate;
    const endDate = deliverySchedule.smsTrigger.details.triggerConditionEndDate;
    const alwaysOn =
      deliverySchedule.smsTrigger.details.triggerConditionAlwaysOn;
    if (startDate) {
      deliverySchedule.smsTrigger.details.triggerConditionStartDate = moment
        .utc(startDate)
        .toDate();
    }
    if (endDate) {
      deliverySchedule.smsTrigger.details.triggerConditionEndDate = moment
        .utc(endDate)
        .toDate();
    }
    if (alwaysOn) {
      delete deliverySchedule.smsTrigger.details.triggerConditionEndDate;
    }
  }

  if (get(deliverySchedule, 'sendAfter.value') === 'DontSend') {
    additionalData['$unset'] = { followUp: '' };
  }

  // disable follow up for first release.
  if (deliverySchedule) {
    deliverySchedule.sendAfter = { label: "Don't send", value: 'DontSend' };
  }

  if (status === constant.EMAIL_STATUS.LIVE) {
    additionalData.completionDate = new Date();
  }

  return {
    deliverySchedule: deliverySchedule,
    shortURL,
    userEmail: userEmail,
    userName: userName,
    status: status,
    requestInfoText: requestInfoText,
    requestInfoAnswerText: requestInfoAnswerText,
    nmiAssignedToRequestor: nmiAssignedToRequestor,
    shareCostEstimate: shareCostEstimate,
    acsCostFileFormData: acsCostFileFormData,
    isEdited: isEdited,
    ...additionalData
  };
};

const transformSMSPayloadByDesign = data => {
  const clonedData = cloneDeep(data);
  const {
    details,
    userEmail,
    userName,
    status,
    isFollowUp,
    uid,
    changesDescription,
    role
  } = clonedData;
  const { URLMain } = details || {};
  const { fullURL = '', shortURL = '' } = URLMain || {};

  const smsDetails = [];

  smsDetails.push({
    optOutText: status == 4 ? details.optOutText : '',
    smsContent: details.smsContent,
    ...(details.URLMain && {
      URL: [
        {
          fullURL,
          shortURL: shortURL
        }
      ]
    })
  });
  // smsDetails[0]['broadcastDate'] =
  // smsDetails[0]['broadcastTime'] =
  if (isFollowUp) {
    smsDetails.push({
      // optOutText: details.optOutTextFollowUp,
      smsContent: details.smsContentFollowUp,
      ...(details.URLFollowup && {
        URL: [{ fullURL: details.URLFollowup.fullURL }]
      })
    });
  }

  return {
    smsDetails: smsDetails,
    additionalSMSRequired: isFollowUp ? true : false,
    additionalInformation: details.additionalInformation,
    userEmail: userEmail,
    userName: userName,
    status: status,
    uid: uid,
    changesDescription: changesDescription,
    role: role
  };
};

const SMSSubmitRedirectURL = ({
  backToReview,
  status,
  uid,
  nextPage,
  userGroup = ''
}) => {
  if (backToReview) {
    if (['4'].includes(status) && ['acs', 'admin'].includes(userGroup)) {
      return `${constant.SMS_BASE_ROUTE}/${uid}/${constant.SMS_ROUTES.EXECUTION}`;
    }
    if (status === constant.SMS_STATUS.AWAITING_FOR_APPROVAL) {
      return `${constant.SMS_BASE_ROUTE}/${uid}/${constant.SMS_ROUTES.APPROVAL}`;
    } else if (
      status === constant.SMS_STATUS.DRAFT ||
      status === constant.SMS_STATUS.REJECTED
    ) {
      return `${constant.SMS_BASE_ROUTE}/${uid}/${constant.SMS_ROUTES.REVIEW}`;
    }
  } else {
    return `${constant.SMS_BASE_ROUTE}/${uid}/${nextPage}`;
  }
};

const getSMSCampStepProgressDate = (status, step, campaignHistory) => {
  if (status === 'is-done') {
    if (
      campaignHistory &&
      Array.isArray(campaignHistory) &&
      !isEmpty(campaignHistory)
    ) {
      let event = constant.SMS_CAMPAIGN_HISTORY_PROGRESS_STEP_MAP[step.id];
      let eventLog =
        campaignHistory && campaignHistory.find(elem => elem.event === event);
      let eventDate = eventLog
        ? moment(eventLog.eventDate).format('DD-MMM-YYYY')
        : moment().format('DD-MMM-YYYY');
      return eventDate;
    }
    return moment().format('DD-MMM-YYYY');
  } else if (status === 'is-now') {
    return 'Now';
  } else {
    return 'Later';
  }
};

const getSMSStepProgressStatus = (index, progressIndex, totalSteps, step) => {
  function checkIfSumbmited() {
    return progressIndex === 0 || index === totalSteps - 1
      ? index === progressIndex + 1
      : false;
  }

  function checkIfLive() {
    return index === progressIndex
      ? step.status.includes(constant.SMS_STATUS.LIVE) ||
          step.status.includes(constant.SMS_STATUS.SCHEDULED)
      : false;
  }
  // || progressIndex === totalSteps - 1
  if (index < progressIndex || index === 0 || checkIfLive()) {
    return 'is-done';
  } else if (index === progressIndex || checkIfSumbmited()) {
    return 'is-now';
  } else {
    return 'is-later';
  }
};

const getSMSCampaignStatusSteps = (campaign = {}, campaignHistory = []) => {
  let progressSteps = [];
  let progresStepList = constant.SMS_CAMPAIGN_TIMELINE_STEPS().filter(elem => {
    if (
      !constant.featureFlags.EMAIL_APPROVAL_REQUIRED &&
      elem.status.includes(constant.SMS_STATUS.AWAITING_FOR_APPROVAL) &&
      campaign.status !== constant.SMS_STATUS.AWAITING_FOR_APPROVAL
    ) {
      return false;
    }
    if (
      !constant.featureFlags.EMAIL_EXECUTION_REQUIRED &&
      (elem.status.includes(constant.SMS_STATUS.AWAITING_SETUP) ||
        elem.status.includes(constant.SMS_STATUS.SCHEDULED) ||
        elem.status.includes(constant.SMS_STATUS.LIVE)) &&
      ![
        constant.SMS_STATUS.AWAITING_SETUP,
        constant.SMS_STATUS.SCHEDULED,
        constant.SMS_STATUS.LIVE
      ].includes(campaign.status)
    ) {
      return false;
    }
    return true;
  });
  let totalSteps = progresStepList.length;
  let progressIndex = progresStepList.findIndex(elem =>
    elem.status.includes(campaign.status)
  );
  progresStepList.forEach((step, index) => {
    let status = getSMSStepProgressStatus(
      index,
      progressIndex,
      totalSteps,
      step
    );
    let title = step.stepName;
    progressSteps.push({
      title: title,
      subTitle: step.stepDescription,
      completedAt: getSMSCampStepProgressDate(status, step, campaignHistory),
      status: status,
      id: step.id
    });
  });

  return progressSteps;
};

const checkAccessToSMS = (user, smsCampaign, pageName) => {
  if (
    window.location.pathname.includes('/newcommunications') ||
    window.location.pathname.includes('/communications') ||
    window.location.pathname.includes('/sms-dashboard')
  ) {
    if (
      !window.location.pathname.includes('/sms-dashboard') &&
      !window.location.pathname.includes('/newcommunications') &&
      !['sms-overview', 'approval'].includes(pageName)
    ) {
      return !checkSMSCampaignEditRights(smsCampaign, user);
    }

    return false;
  }
};
const isWhatsAppConsentEnableForMarket = (config, countryCode) => {
  if (
    config &&
    config.WHATSAPP_CONSENT &&
    config.WHATSAPP_CONSENT.ALL_MARKET === false
  ) {
    if (
      config &&
      config.WHATSAPP_CONSENT &&
      config.WHATSAPP_CONSENT.COUNTRY_CODE &&
      config.WHATSAPP_CONSENT.COUNTRY_CODE.length &&
      countryCode
    ) {
      const market = config.WHATSAPP_CONSENT.COUNTRY_CODE.find(
        e => e.code === countryCode
      );
      if (market) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const getFullUUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  console.log('getFullUUID uuid: ', uuid);
  return uuid;
};

const hasPersmissionToUploadEmailCouponFile = (
  requester,
  currentUser,
  emailCollaborators
) => {
  const collaborators = [];
  const { emailId, group } = currentUser;

  if (emailCollaborators.type === 'requestorAndSelectedUsers') {
    collaborators.push(
      ...emailCollaborators.collaborators.map(item => item.value.toUpperCase())
    );
  }

  return (
    emailId === requester ||
    constant.EMAIL_DYNAMIC_COUPON_UPLOAD_ROLES.includes(group) ||
    collaborators.includes(emailId.toUpperCase())
  );
};

const hasPersmissionToUploadSMSCouponFile = (
  requester,
  currentUser,
  emailCollaborators
) => {
  const collaborators = [];
  const { emailId, group, email } = currentUser;
  const userEmail = emailId || email;

  if (emailCollaborators.type === 'requestorAndSelectedUsers') {
    collaborators.push(
      ...emailCollaborators.collaborators.map(item =>
        item?.value?.toUpperCase()
      )
    );
  }

  return (
    userEmail === requester ||
    constant.SMS_DYNAMIC_COUPON_UPLOAD_ROLES.includes(group) ||
    collaborators.includes(userEmail.toUpperCase())
  );
};
const getSMSCostAndMoreInfoRequestOptions = (type, defaultValue) => {
  let returnValue = constant.SMS_COST_AND_REQUEST_INFO_DROP_DOWN_LIST;
  return returnValue;
};

const userHasAwaitingPurchaseOrderPermission = (
  requester,
  currentUser,
  emailCollaborators
) => {
  const collaborators = [];
  const { email, group } = currentUser;

  if (emailCollaborators.type === 'requestorAndSelectedUsers') {
    collaborators.push(
      ...emailCollaborators.collaborators.map(item =>
        item?.value?.toUpperCase()
      )
    );
  }

  return (
    email === requester ||
    collaborators.includes(email.toUpperCase()) ||
    constant.SMS_PURCHASE_ORDER_ACCESS_ROLES.includes(group)
  );
};

const userHasResubmitPermission = (
  requester,
  currentUser,
  emailCollaborators
) => {
  const collaborators = [];
  const { email, group } = currentUser;

  if (emailCollaborators.type === 'requestorAndSelectedUsers') {
    collaborators.push(
      ...emailCollaborators.collaborators.map(item => item.value.toUpperCase())
    );
  }

  return (
    email === requester ||
    collaborators.includes(email.toUpperCase()) ||
    constant.SMS_RESUBMIT_ROLES.includes(group)
  );
};

const getSubmittedSMSCampaignScheduleDates = data => {
  let dateObject = {};
  if (data && data[0] && data[0].smsContent) {
    let date = null;
    if (data[0].broadcastDate && data[0].broadcastTime) {
      date = `${moment(data[0].broadcastDate, 'DD/MM/YYYY').format(
        'DD MMM YYYY'
      )}${
        data[0].broadcastTime
          ? `, ${data[0].broadcastTime.toLowerCase()} UTC`
          : ''
      }`;
    }
    dateObject = {
      date1: date
    };
  }
  if (data && data[1] && data[1].smsContent) {
    let date = null;
    if (data[1].broadcastDate && data[1].broadcastTime) {
      date = `${moment(data[1].broadcastDate, 'DD/MM/YYYY').format(
        'DD MMM YYYY'
      )}${
        data[1].broadcastTime
          ? `, ${data[1].broadcastTime.toLowerCase()} UTC`
          : ''
      }`;
    }
    dateObject = { ...dateObject, date2: date };
  }
  return dateObject;
};

export {
  stringReplaceAll,
  getCampaignTypeText,
  getCampaignDetails,
  getQuesAnswerList,
  getCampaignData,
  getCampaignStatus,
  getCampaignConsentIdDetails,
  getFormatedDate,
  textCapitalization,
  getSplitDashboardArray,
  sortArray,
  checkIsEmailAdminOrACS,
  numberFormat,
  isAEMTemplateCampaign,
  scrollToTop,
  showGigyaIdScreen,
  statusBadge,
  getCampaignStatusText,
  getUserCountryList,
  getDateRange,
  getPageBreadCrumb,
  generateRoleStatusBasedUrl,
  checkCampaignLanguage,
  showCampaignLanguage,
  isQuestionMandatory,
  isQuesQualifying,
  isRestrictionAllowed,
  isCampaignEdited,
  showCampaignEditButton,
  str2bool,
  masterDataCampaignStatus,
  _generateUUID,
  checkMarketSupportedForDoubleOptin,
  isAEMTypeCampaign,
  getFormatedDateNew,
  getSmsCampaignStatusText,
  isLanguageRTL,
  isGigyaKeySubmitted,
  showAutoGenerateFlag,
  epsilonMarket,
  showEditFormForSaas,
  showMasterDataIcon,
  getDateInDDmmmYYYY,
  checkIsCIAEnabled,
  debounceFunction,
  fetchQnANotificationTileData,
  getDistinctBusinessFilters,
  getNewFormatedDate,
  getNewCampaignTypeName,
  getCampaignStatusSteps,
  getYupSchema,
  transformEmailBriefingData,
  transformEmailBriefingPayload,
  transformSMSPayloadByAudience,
  checkCustomRulesList,
  checkAccessToEmailBrefing,
  getFeedbackDownloadLink,
  getFieldValue,
  filterToQuery,
  isConsentFieldEditable,
  isBrandEmailSmsConsentVisible,
  hasAEMFlow,
  getChartData,
  transformUsageOfQuestiosnData,
  checkForNullOrUndefined,
  getEmailCampaignStatusSteps,
  checkEmailRouteAccess,
  checkApproverCampaignEmailAccess,
  transformToURLQueryString,
  checkRouteAccess,
  checkAccessToEmailBrefingV2,
  addMetaLangToTemplate,
  isMDRequiredForQnA,
  checkEmailCampaignEditRights,
  checkSMSCampaignEditRights,
  checkEmailCampaignSendTestRights,
  getAudienceSendEmailTolabel,
  getDeliveryCondition,
  getSMSDeliveryCondition,
  getRowAndVariantComboInfo,
  hasEmailContentABTest,
  applyDefaultStyles,
  hasSubjectLineABTest,
  isUnbundledConsentAndMarketCheck,
  shouldRedirectToExecution,
  getEmailScheduleTriggerOptions,
  getSMSScheduleTriggerOptions,
  getABTestReviewPageErrorMesg,
  isQnAAvailableInCampaign,
  addWatermarkInTemplateImage,
  getBlobFromBuffer,
  imageURLComponents,
  maskData,
  userHasCouponCodePermission,
  checkIfUserIsRequestorOrApprover,
  getHeaderNavMenuItems,
  getSMSPreviewData,
  getSMSReviewData,
  getSMSAudienceOptions,
  transformSMSPayloadBySchedule,
  transformSMSPayloadByDesign,
  SMSSubmitRedirectURL,
  transformSMSDataForValidation,
  getSMSCampaignStatusSteps,
  checkAccessToSMS,
  isWhatsAppConsentEnableForMarket,
  getFullUUID,
  hasPersmissionToUploadEmailCouponFile,
  hasPersmissionToUploadSMSCouponFile,
  getSMSCostAndMoreInfoRequestOptions,
  userHasAwaitingPurchaseOrderPermission,
  userHasResubmitPermission,
  getSubmittedSMSCampaignScheduleDates,
  getNewFormatedTime,
  getDateInDDmmmYYYYTime
};
