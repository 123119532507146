import React from 'react';
import ReactTooltip from 'react-tooltip';

import { maskData } from 'selectors';

import constants from 'constants/config';
import moment from 'moment';

export const CouponCodeSmsDisplay = ({
  smsAttributes,
  userPermitted,
  couponFileTrack
}) => {
  const couponType = smsAttributes?.smsCoupon?.type;

  if (couponType === 'staticCoupon') {
    return (
      <>
        {userPermitted
          ? smsAttributes?.smsCoupon?.code
          : maskData(smsAttributes?.smsCoupon?.code)}
        <span
          className="icon"
          style={{ cursor: 'pointer' }}
          data-tip="The coupon code is visible only to campaign owner, collaborators, approvers, ACS execution team and admins. Anyone else will see the coupon code as masked"
          data-for="staticCoupon"
        >
          &nbsp;<i className="fas fa-question-circle"></i>
        </span>
        <ReactTooltip
          id="staticCoupon"
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip cw-email-tooltip"
        />
      </>
    );
  } else if (couponType === 'dynamicCoupon') {
    if (smsAttributes?.smsCoupon?.couponFileUpload === 'success') {
      const couponFileName = couponFileTrack?.couponFileName;
      const couponFileUploadTime = couponFileTrack?.couponFileUploadTime;
      return `Coupon codes sent to ACS on ${moment(couponFileUploadTime).format(
        'DD MMM YYYY'
      )} in file '${couponFileName}'`;
    }
    if (smsAttributes?.smsCoupon?.couponFileUpload === 'failed') {
      if (smsAttributes?.smsCoupon?.shareCouponFileViaKana) {
        return smsAttributes?.smsCoupon?.kanaTicketNumber
          ? `Coupon upload from CW to ACS failed. Coupons have instead been uploaded via Kana ticket ${smsAttributes.smsCoupon.kanaTicketNumber}`
          : 'Awaiting Kana ticket number from requestor';
      }
    }
    return 'Awaiting coupon codes file upload from requestor';
  }

  return smsAttributes?.smsCoupon?.kanaTicketNumber
    ? `Dynamic coupons uploaded via Kana ticket ${smsAttributes.smsCoupon.kanaTicketNumber}`
    : 'Awaiting Kana ticket number from requestor';
};

export const couponDisplay = (couponApplied, couponType) =>
  couponApplied
    ? couponType === 'dynamicCoupon'
      ? 'SMS includes dynamic coupons'
      : `Includes ${constants.EMAIL_COUPON_TYPE[couponType]} in SMS`
    : 'No coupons in SMS';
