import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import formSchema from './campaignAttributesSchema';
import { getEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import ProgressBar from 'components/molecules/ProgressBar';
import NewUpdateAlert from 'components/molecules/NewUpdateAlert';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import constants from 'constants/config';

const CampaignAttributes = props => {
  const { isLoading } = useSelector(state => state.emailCampaignDetails);
  const { emailBriefing } = useSelector(state => state.emailDesign);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const [showDynamicCouponAlert, setShowDynamicCouponAlert] = useState(false);
  const dispatch = useDispatch();

  const pathUid = props.match.params.id;
  useEffect(() => {
    if (pathUid) {
      // fetch from email details from the server
      dispatch(getEmailCampaignDetails(pathUid, { type: 'abTesting' }));
    }
  }, []);

  const toggleShowDynamicCouponAlert = e => {
    if (e.target.id === 'dynamicCoupon') {
      setShowDynamicCouponAlert(true);
    } else {
      setShowDynamicCouponAlert(false);
    }
  };

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: emailBriefing,
    toggleShowDynamicCouponAlert
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {/* <BreadCrumb match={props.match} emailBriefing /> */}
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.CAMPAIN_ATTRIBUTES}
        />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Campaign attributes
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              user={authorizedUserData}
              customButtonsComp={CustomButtons}
            />
          </div>
          <div className="col-md-4">
            {showDynamicCouponAlert && (
              <NewUpdateAlert
                className="coupon-upload-feat-box mt-30"
                title="Upload dynamic coupons via CW!"
                content={
                  <>
                    <p>
                      You can now send your dynamic coupons code files to ACS
                      via Campaign Wizard and do not need to raise a kana ticket
                    </p>
                  </>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignAttributes;
