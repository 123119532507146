import React, {useState} from 'react';
import { Menu } from 'semantic-ui-react';

function Toolbar({ children }) {
  return <Menu className="toolbar-component">{children}</Menu>;
}

const ToolbarItem = ({
  icon,
  name,
  onClick,
  fontIcon = null,
  svgIcon: SvgIcon = null
}) => (
  <>
    <span className="mr-2">
      <Menu.Item
        className="toolbar-component--tool_span"
        name="print"
        onClick={onClick}
        style={{ padding: '2px,13px' }}
      >
        {fontIcon ? (
          <i className={fontIcon} style={{ color: 'var(--color-primary)' }} />
        ) : icon ? (
          <img src={icon} alt="" style={{ height: '40px', width: '40px' }} />
        ) : (
          ''
        )}{' '}
        {SvgIcon ? (
          <span className="mr-2">
            <SvgIcon />
          </span>
        ) : null}
        {name}
      </Menu.Item>
    </span>
    <span className="ml-1 toolbar-component--vr"></span>
  </>
);

const ToolbarDownloadItem = ({ icon, name, onClick, fontIcon = null ,svgIcon : SvgIcon = null}) => { 
  const [isLoading, setIsLoading] = useState(false)
  return(
  <>
    <span className="mr-2 downloadLinkContainer">
    
    <Menu.Item
        className="toolbar-component--tool_span"
        name="print"
        onClick={isLoading? undefined : (e) => onClick(e, setIsLoading)}
        style={{ padding: '2px,13px' }}
      >
        {fontIcon? <i
                  className={fontIcon}
                  style={{ color: 'var(--color-primary)' }}
                />: (icon ? <img src={icon} alt="" style={{ height: '40px', width: '40px' }} /> : '')}{' '} 
                {SvgIcon ? 
                  <span className="mr-2"><SvgIcon/></span> : null
                }
        {name}
    </Menu.Item>
    {isLoading? <div className='dw-loader'>
    <span>
      <i className="fa fa-spinner fa-spin" /> Downloading
    </span>
		</div> : null
    }
    </span>
    <span className="ml-1 toolbar-component--vr"></span>
  </>
)};

Toolbar.Item = ToolbarItem;
Toolbar.DownloadItem = ToolbarDownloadItem;

export default Toolbar;
