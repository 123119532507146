import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import { submitFeedback } from 'actions/feedbackForm';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import TemplatePreview from 'components/organisms/TemplatePreview/TemplatePreview';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import { updateEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import EmailTest from 'components/EmailTest';
import Modal from 'react-modal';
import ProgressBar from 'components/molecules/ProgressBar';
import { exportEmailHTML } from 'services/emailTemplateEdit';
import EmailSummaryCards from 'components/organisms/EmailSummaryCards/EmailSummaryCards';
import EmailOverviewTables from 'components/organisms/EmailOverviewTables/EmailOverviewTables';
import WinningVariantInfo from 'components/organisms/WinningVariantInfo';
import constants from '../../constants';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import {
  validateEmailBriefing,
  validateEmailContent
} from 'pages/EmailOverview/utilities';
import { FormError } from 'components/molecules/FormError';
import SatisfactoryRatingsInput from 'components/SatisfactoryRatingsInput';
import { ReviewPageKanaTicketInput } from 'components/organisms/ReviewPageKanaTicketInput';
import { isEmpty, get } from 'lodash';
import Select from 'react-select';
import * as yup from 'yup';

import {
  scrollToTop,
  hasSubjectLineABTest,
  getABTestReviewPageErrorMesg
} from 'selectors';
import classNames from 'classnames';

export default function EmailReview(props) {
  const { match, history } = props;
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    false
  );
  //const [shouldShowFeedbackSection, setshouldShowFeedbackSection] = useState(true);
  const [feedback, setFeedback] = useState({});
  const [requestInfoAnswer, setRequestInfoAnswer] = useState({});
  useEffect(() => {
    const uid = props.match.params.id;
    dispatch(getEmailBriefing(uid));
  }, []);

  const emailProps = useSelector(state => ({
    historyLogs: state.historyLogs,
    emailBriefing: state.emailDesign.emailBriefing,
    campaignConfig: state.app.campaignConfig
  }));

  const [dynamicCouponChecked, setDynamicCouponChecked] = useState(false);
  const [kanaTicketNumber, setKanaTicketNumber] = useState(
    emailProps.emailBriefing?.emailAttributes?.emailCoupon?.kanaTicketNumber ||
      ''
  );

  useEffect(() => {
    setKanaTicketNumber(
      emailProps.emailBriefing?.emailAttributes?.emailCoupon?.kanaTicketNumber
    );
  }, [emailProps.emailBriefing?.emailAttributes]);

  const { authorizedUserData } = useSelector(state => state.authorized);
  const { feedbacks: userSubmittedFeebacks } = useSelector(
    state => state.feedbackForm
  );

  const hasDynamicCoupon =
    emailProps.emailBriefing?.emailAttributes?.emailCoupon?.type ===
    'dynamicCoupon';

  if (!emailProps.emailBriefing) return null;
  const { emailTemplateEditId, summary } =
    emailProps && emailProps.emailBriefing;
  const campaignName = (summary && summary.campaignName) || 'Untitled';

  const shouldShowFeedbackSection = isEmpty(userSubmittedFeebacks);

  const validateFeedbackForm = async feedbackData => {
    const feedbackSchema = yup.object({
      rw_ratings: yup.string().required(),
      rw_comments: yup.string()
    });
    if (shouldShowFeedbackSection) {
      return await feedbackSchema.isValid(feedbackData);
    } else {
      return true;
    }
  };
  const validateRequestorInfoAnswer = async emailBrifing => {
    const { requestInfoAnswerText } = emailBrifing;
    const requestorInfoSubmitSchema = yup.object({
      requestInfoAnswerText: yup.string().required()
    });

    return await requestorInfoSubmitSchema.isValid({
      requestInfoAnswerText: requestInfoAnswerText
    });
  };

  const validateKanaTicketInput = async data => {
    const kanaTicketSchema = yup
      .string()
      .required(
        'Please enter the Kana ticket number used for providing coupons to ACS team'
      )
      .matches(
        /^CS\d{7}$/,
        "Please enter a valid Kana ticket in format 'CS1234567'"
      )
      .max(9, "Please enter a valid Kana ticket in format 'CS1234567'");

    if (hasDynamicCoupon) {
      try {
        await kanaTicketSchema.validate(data);
        return false;
      } catch (e) {
        return e.message;
      }
    } else {
      return false;
    }
  };

  const handleNextClick = async () => {
    const { emailBriefing } = emailProps;
    const errors = await validateEmailBriefing(emailBriefing);
    const {
      summary,
      campaignAttributes,
      deliverySchedule,
      audienceDefinition,
      // emailCollaborators,
      emailProperties,
      emailABTesting,
      emailTemplateEditId,
      dynamicCouponError
    } = errors;
    const feedbackError = await validateFeedbackForm(feedback);
    const feedbackCommentErr =
      feedback.rw_ratings <= '3' &&
      (!feedback.rw_comments || feedback.rw_comments.trim().length == 0)
        ? true
        : false;

    let requestInfoError = true;
    if (emailBriefing && emailBriefing.requestInfoText) {
      requestInfoError = await validateRequestorInfoAnswer(requestInfoAnswer);
    }
    const emailContentError = validateEmailContent(
      emailBriefing,
      emailBriefing.emailTemplateEditId
    );
    //const kanaTicketNumberError = await validateKanaTicketInput(kanaTicketNumber)

    if (
      summary ||
      campaignAttributes ||
      deliverySchedule ||
      audienceDefinition ||
      // emailCollaborators ||
      emailProperties ||
      emailTemplateEditId ||
      emailABTesting ||
      !feedbackError ||
      emailContentError ||
      !requestInfoError ||
      dynamicCouponError ||
      feedbackCommentErr
    ) {
      setErrors({
        ...errors,
        feedbackError: !feedbackError,
        emailContentError,
        requestAnswererror: !requestInfoError,
        campaignAttributes: dynamicCouponError ? true : campaignAttributes,
        feedbackCommentError: feedbackCommentErr
      });
      scrollToTop();
      return;
    }

    const paramUid = props.match.params.id;
    if (shouldShowFeedbackSection) {
      let feedbackData = {
        ratings: feedback.rw_ratings,
        campaignRequestType: 'Email campaigns',
        commentType: get(feedback, 'rw_commentType.value'),
        comments: feedback.rw_comments,
        campaignUid: paramUid,
        feedbackProvider: authorizedUserData._id
      };
      dispatch(
        submitFeedback(feedbackData, (error, result) => {
          if (error) {
            console.log(error);
          } else {
            console.log(result);
          }
        })
      );
    }

    let dataToSend = {
      status: constants.featureFlags.EMAIL_APPROVAL_REQUIRED
        ? constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL
        : constants.featureFlags.EMAIL_EXECUTION_REQUIRED
        ? constants.EMAIL_STATUS.AWAITING_SETUP
        : constants.EMAIL_STATUS.SUBMITTED,
      requestersEmail: props.userEmail,
      submittedDate: new Date(),
      userEmail: props.userEmail,
      userName: props.userName,
      isEmailApprovalRequired: constants.featureFlags.EMAIL_APPROVAL_REQUIRED,
      emailAttributes: emailBriefing.emailAttributes
        ? {
            emailCoupon: {
              ...emailBriefing.emailAttributes.emailCoupon,
              kanaTicketNumber,
              dynamicCouponConsent: hasDynamicCoupon ? true : false
            }
          }
        : {}
    };
    if (emailBriefing && emailBriefing.requestInfoText) {
      dataToSend = {
        ...dataToSend,
        requestInfoAnswerText:
          requestInfoAnswer && requestInfoAnswer.requestInfoAnswerText
      };
    }

    if (dataToSend.status === constants.EMAIL_STATUS.SUBMITTED) {
      dataToSend.completionDate = dataToSend.submittedDate;
    }

    dispatch(
      updateEmailCampaignDetails(paramUid, dataToSend, (error, result) => {
        if (result) {
          const uid = props.match.params.id;
          props.history.push(
            `/campaign-wizard/emailbriefing/${uid}/email-submit`
          );
        }
      })
    );
  };

  const onClickEdit = () => {
    const uid = match.params.id;
    history.push({
      pathname: `/campaign-wizard/emailbriefing/${uid}/email-editor`
    });
  };

  const openTestEmailModel = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  /**
   * Export button click handler
   */
  const onExportHandler = () => {
    const res = exportEmailHTML(
      props.match.params.id,
      props.userEmail,
      props.userName,
      { campaignName }
    );
  };

  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const refetchEmailBriefing = () => {
    const uid = props.match.params.id;
    // if (!emailBriefing || emailBriefing.uid !== uid) {
    dispatch(getEmailBriefing(uid, { disableLoader: true }));
  };

  const feedbackCommentOptions = [
    { label: 'Suggestion', value: 'suggestion' },
    { label: 'Compliment', value: 'compliment' },
    { label: 'Complaint', value: 'complaint' },
    { label: 'New request', value: 'newFeatureRequest' }
  ];

  const isError =
    errors.summary ||
    errors.campaignAttributes ||
    errors.deliverySchedule ||
    errors.audienceDefinition ||
    // errors.emailCollaborators ||
    errors.emailProperties ||
    errors.emailABTesting ||
    errors.emailTemplateEditId ||
    errors.feedbackError ||
    errors.emailContentError ||
    errors.requestAnswererror ||
    errors.dynamicCouponError ||
    errors.feedbackCommentError;

  const isAbTestContentError = errors.emailContentError;
  const isAbTestSubjectError = hasSubjectLineABTest(emailProps.emailBriefing)
    ? errors.emailProperties
    : false;

  const handleFeedbackInputChange = event => {
    if (event.target.name === 'rw_ratings') {
      setErrors({
        ...errors,
        feedbackError: false,
        feedbackCommentError: false
      });
    }
    if (
      event.target.name === 'rw_comments' &&
      event.target.value.trim().length
    ) {
      setErrors({ ...errors, feedbackCommentError: false });
    }
    let newFeedback = {
      ...feedback,
      [event.target.name]: event.target.value
    };
    setFeedback(newFeedback);
  };

  const handleFeedbackCommentType = value => {
    let newFeedback = {
      ...feedback,
      rw_commentType: value
    };
    setFeedback(newFeedback);
  };

  const handleRequestInfoAnswer = event => {
    let newRequestInfoAnswer = {
      ...requestInfoAnswer,
      requestInfoAnswerText: event.target.value
    };
    setErrors({
      ...errors,
      requestAnswererror: false
    });
    setRequestInfoAnswer(newRequestInfoAnswer);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--test-email-model"
        contentLabel="Field Types"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailTest
          onClose={closeModal}
          userEmail={props.userEmail}
          uid={props.match.params.id}
          userName={props.userName}
          userGroup={authorizedUserData.group}
          emailBriefing={emailProps.emailBriefing}
          refetchEmailBriefing={refetchEmailBriefing}
        />
      </Modal>
      <div className="skypink ml-5 mr-5">
        <div className="cw-section">
          <ProgressBar
            customClass={'cw-email-progress-custom'}
            pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.REVIEW}
          />
          <PageHeader heading="Review and submit campaign" />
          <div className="d-flex">
            <div
              style={{
                flex: 1,
                marginRight: 40,
                minWidth: 0,
                flexDirection: 'column',
                display: 'flex'
              }}
            >
              <div className="mb-20">
                {isError ? (
                  <FormError
                    className="mb-15"
                    message={constants.EMAIL_REVIEW_ERROR_MESSAGE}
                    isNotBold
                  />
                ) : null}
                {isAbTestContentError || isAbTestSubjectError ? (
                  <FormError
                    className="mb-15"
                    message={getABTestReviewPageErrorMesg({
                      isAbTestContentError,
                      isAbTestSubjectError
                    })}
                    isNotBold
                  />
                ) : null}
              </div>
              <EmailSummaryCards emailProps={emailProps} />
              <EmailOverviewTables
                emailProps={emailProps}
                review
                editable
                errors={errors}
                handleTemplatePreview={handleTemplatePreview}
                handleRequestInfoAnswer={handleRequestInfoAnswer}
                requestInfoAnswerFlag={true}
              />
              {shouldShowFeedbackSection && (
                <div className="cw-campaign--review-section mb-10 mt-10">
                  <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                    <h3 className="email-tabular__heading">
                      Rate your experience
                    </h3>
                  </div>

                  <div className="form-group mb-20">
                    <label>
                      Please rate your experience using Campaign Wizard
                    </label>
                    <SatisfactoryRatingsInput
                      name="rw_ratings"
                      onChange={handleFeedbackInputChange}
                      value={feedback.rw_ratings}
                      onBlur={() => {}}
                      idPrefix="rw_"
                    />
                    {errors.feedbackError && (
                      <span id="ratingsFieldMessage" className="cw-error">
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please rate your experience
                      </span>
                    )}
                  </div>

                  <div className="form-group mb-20"></div>

                  <div className="form-group">
                    <label>
                      Please provide comments so we can action on your input
                      &#40;Optional&#41;
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Comment type</label>
                    <Select
                      className={'cw-select--custom'}
                      name={'rw_commentType'}
                      label={'rw_commentType'}
                      placeholder={'Please select'}
                      options={feedbackCommentOptions}
                      onChange={handleFeedbackCommentType}
                      value={feedback.rw_commentType}
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      className={classNames({
                        'form-control': true,
                        'cw-error-focus': errors.feedbackCommentError
                      })}
                      name="rw_comments"
                      id="rw_comments"
                      rows="3"
                      maxLength="500"
                      data-required="true"
                      placeholder="Please provide your suggestions, complaints, compliments or new feature requests"
                      onChange={handleFeedbackInputChange}
                      value={feedback.rw_comments || ''}
                      onBlur={() => {}}
                    ></textarea>
                  </div>
                  {errors.feedbackCommentError && (
                    <span id="ratingsFieldMessage" className="cw-error">
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter your comments
                    </span>
                  )}
                </div>
              )}

              {/* <ReviewPageKanaTicketInput 
                hasDynamicCoupon={hasDynamicCoupon}
                toggleCheckbox={setDynamicCouponChecked}
                dynamicCouponChecked={dynamicCouponChecked}
                kanaTicketNumber={kanaTicketNumber}
                setKanaTicketNumber={setKanaTicketNumber}
                error={errors.kanaTicketNumberError}
              /> */}

              <BackAndNext
                onClickBack={() => {
                  const uid = props.match.params.id;
                  props.history.push(
                    `/campaign-wizard/emailbriefing/${uid}/email-editor`
                  );
                }}
                onClickNext={handleNextClick}
                nextTitle="Submit"
              />
            </div>
            <div className="winning-variant-wrapper">
              {errors.emailTemplateEditId && (
                <p>
                  <span className={'cw-error email-template-error'}>
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please{' '}
                    <Link
                      to={`/campaign-wizard/emailbriefing/${props.match.params.id}/email-template`}
                    >
                      design
                    </Link>{' '}
                    your email
                  </span>
                </p>
              )}
              <TemplatePreview
                template={
                  emailTemplateEditId && emailTemplateEditId.templateObject
                }
                thumbnail={emailTemplateEditId && emailTemplateEditId.thumbnail}
                emailBriefing={emailProps.emailBriefing}
                title={emailTemplateEditId && emailTemplateEditId.title}
                sentTestEmailOnClick={openTestEmailModel}
                onClickEdit={onClickEdit}
                onExport={onExportHandler}
                isExportButton={false}
                isAbTestError={isAbTestContentError}
              />
              <WinningVariantInfo
                className="mt-10"
                emailBriefing={emailProps.emailBriefing}
              />
            </div>
          </div>
        </div>
      </div>
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        hideSelectBtn
      />
    </>
  );
}
