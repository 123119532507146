import React from 'react';
import Modal from 'react-modal';
import ConsentField from '../ConsentField/ConsentField';
import constants from 'constants/config';

class ConsentsSection extends React.Component {
  state = {
    modalIsOpen: false
  };

  _openModal = event => {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  };

  _closeModal = () => {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  };

  render() {
    const {
      consents,
      consentFieldsValidity,
      _handleLegalAgeConsentInputChange,
      _handleInputChange,
      handleAccordionExpandCollapse,
      removeAccordion,
      baseIndex,
      updateConsent,
      consentsUnavailable,
      countrySelected,
      isEdited,
      _handleReset,
      consentsTest,
      expand,
      isLegalAge,
      nonRemovableConsents,
      isEpsilonMarket,
      isMasterData,
      disableMandatoryToggle,
      campaignType,
      campaignConfig,
      isUnbundledMarket,
      isPlatformAgnostic,
      isCombinedStatement,
      _handleLatastApprovedConsent,
      userrole,
      handleMultiChannelCheckboxClick,
      isOpenMultiChannelGuideModel,
      incentiveType
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this._closeModal}
          className="cw-modal cw-modal--questions"
          contentLabel="Field Types"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={this._closeModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className="cw-heading--primary mb-10">Display options</h3>
          <div className="col-sm-12">
            <div className="row cw-bgpattern-primary">
              <div className="col-sm-4">
                <strong>Checkbox</strong>
              </div>
              <div className="col-sm-8">
                <span>
                  Consumers will have to explicitly select consent checkbox
                </span>
              </div>
            </div>
            <div className="row cw-bgpattern-primary">
              <div className="col-sm-4">
                <strong>
                  Statement (Implicit consent, text merged with another consent)
                </strong>
              </div>
              <div className="col-sm-8">
                <span>
                  Consumers will see the consent as a read only statement on the
                  form. Please note that you need to copy the consent text over
                  to the ‘Privacy policy’ text area. This is required for us to
                  display all legal/consents statements together in the form.
                </span>
                {/* <br />
                <div className="mt-2">
                  If you need a consent statement to be hidden but to be
                  captured true on submission, please mark it as a statement and
                  do not copy over the consent text to the privacy policy.
                  Please use this option only if approved by DPOs. Some valid
                  previously approved usage of this feature includes brand who
                  want to display a single enterprise consent statement for
                  email and mobile instead of 2 separate statements. In such
                  cases, you select a both the consent fields and choose to hide
                  one, while ensuring both are captured true on form submission.
                </div> */}
              </div>
            </div>
            {isEpsilonMarket &&
            campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT ? (
              <div className="row cw-bgpattern-primary">
                <div className="col-sm-4">
                  <strong>Statement (Implicit consent - standalone)</strong>
                </div>
                <div className="col-sm-8">
                  <span>
                    Consumers will see the consent as a read only statement on
                    the form.
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
        {consents.map((elem, index) => {
          return (
            <ConsentField
              id={`consent_${elem.optInType}`}
              elem={elem}
              consentsUnavailable={consentsUnavailable}
              nonRemovableConsents={nonRemovableConsents}
              removeAccordion={removeAccordion}
              handleAccordionExpandCollapse={handleAccordionExpandCollapse}
              _handleLegalAgeConsentInputChange={
                _handleLegalAgeConsentInputChange
              }
              _openModal={this._openModal}
              expand={expand}
              _handleReset={_handleReset}
              _handleInputChange={_handleInputChange}
              expandIndex={baseIndex + index}
              index={index}
              isValid={consentFieldsValidity[index]}
              updateConsent={updateConsent}
              isLegalAge={isLegalAge}
              consentsTest={consentsTest}
              countrySelected={countrySelected}
              isEdited={isEdited}
              isEpsilonMarket={isEpsilonMarket}
              isMasterData={isMasterData}
              disableMandatoryToggle={disableMandatoryToggle}
              campaignType={campaignType}
              campaignConfig={campaignConfig}
              isUnbundledMarket={isUnbundledMarket}
              isPlatformAgnostic={isPlatformAgnostic}
              isCombinedStatement={isCombinedStatement}
              _handleLatastApprovedConsent={_handleLatastApprovedConsent}
              userrole={userrole}
              handleMultiChannelCheckboxClick={handleMultiChannelCheckboxClick}
              isOpenMultiChannelGuideModel={isOpenMultiChannelGuideModel}
              incentiveType={incentiveType}
            />
          );
        })}
      </div>
    );
  }
}

export default ConsentsSection;
