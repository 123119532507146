import React, { forwardRef, useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Mention, MentionsInput } from 'react-mentions';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { get } from 'lodash';
import classNames from 'classnames';

import { FieldError } from 'components/atoms/FieldError';
import { LinkButton } from 'components/atoms/LinkButton';
import Alert from 'components/Alert';

import constants from 'constants/config';
import { getCharCountText, getGSMandUnicodeStats } from 'utilities/util';
import CharecterCountAlert from './CharecterCountAlert';
import { usePrevious } from 'hooks/usePrevious';

const defaultStyle = {
  control: {},

  '&multiLine': {
    display: 'block',
    width: '100%',
    control: {
      minHeight: 86
    },
    highlighter: {
      padding: '0.375rem 0.75rem',
      border: '1px solid transparent'
    },
    input: {
      display: 'block',
      position: 'absolute',
      top: '0px',
      left: '0px',
      padding: '0.375rem 0.75rem',
      paddingRight: '54px',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: 'transparent',
      backgroundClip: 'border-box',
      border: 'none',
      borderRadius: '0.25rem',
      transition: 'border-color .15s'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5'
      }
    }
  }
};

const transformText = (givenText, shortUrl = '') => {
  const textMap = [
    {
      exp: /FName/g,
      text: '[FName]'
    },
    {
      exp: /Coupon/g,
      text: '[Coupon]'
    },
    {
      exp: new RegExp(`${constants.SAMPLE_BITLY_URL}`, 'g'),
      text: `[${constants.SAMPLE_BITLY_URL}]`
    }
  ];
  if (shortUrl) {
    textMap.push({
      exp: new RegExp(shortUrl, 'g'),
      text: `${shortUrl}`
    });
  }
  for (let item of textMap) {
    givenText = givenText.replace(item.exp, item.text);
  }

  return givenText;
};

const findAllTags = (str, regexp) => {
  let match;
  let newList = [];
  while ((match = regexp.exec(str)) !== null) {
    newList.push([match.index, regexp.lastIndex]);
  }
  return newList;
};

const checkIfWord = (cursorPos, list) => {
  let flag = false;
  for (let i = 0; i < list.length; i++) {
    if (cursorPos > list[i][0] && cursorPos < list[i][1]) {
      flag = true;
    }
  }
  return flag;
};

const Input = forwardRef(
  (
    {
      onChange,
      name,
      value,
      maxLength,
      rows,
      label,
      helpText,
      errors,
      defaultValue,
      setValue,
      subText,
      labelTooltip,
      showEmojis,
      flow,
      isDynamicCoupon,
      placeholderTooltipText,
      showCharactorCount,
      optOutTextLength,
      insertingURLMain,
      insertingURLFollowup,
      setInsertingURLMain,
      setInsertingURLFollowup,
      customHelpComponent: CustomHelpComponent,
      customHelpComponentProps,
      detectUnicodeContent,
      optOutText,
      watchValues,
      smsDetails
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const oldWatchValues = usePrevious(watchValues);
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);
    const [isEmojisSelectorOpen, setIsEmojisSelectorOpen] = useState(false);
    const [currentIdentifier, setCurrentIdentifier] = useState('');
    const [cursorPosition, setCursorPosition] = useState(0);

    useEffect(() => {
      if (inputRef && inputRef.current) {
        setCursorPosition(inputRef.current.value.length);
      }

      if (
        name === 'smsContent' &&
        inputRef?.current?.value.includes(constants.SAMPLE_BITLY_URL)
      ) {
        setInsertingURLMain(true);
      }
      if (
        name === 'smsContentFollowUp' &&
        inputRef?.current?.value.includes(constants.SAMPLE_BITLY_URL)
      ) {
        setInsertingURLFollowup(true);
      }
    }, []);

    useEffect(() => {
      const shortUrl = watchValues?.shortURL
        ? watchValues?.shortURL
        : get(smsDetails, '[0].URL.[0].shortURL', '')
        ? `${get(smsDetails, '[0].URL.[0].shortURL')}`
        : '';
      if (!insertingURLMain) {
        const newText = inputRef.current.value.replace(
          shortUrl ? shortUrl : `${constants.SAMPLE_BITLY_URL}`,
          ''
        );
        setValue(`${name}`, newText);
        onChange(transformText(newText, shortUrl));
      } else {
        onChange(transformText(inputRef.current.value, shortUrl));
      }
    }, [insertingURLMain]);

    useEffect(() => {
      if (!insertingURLFollowup) {
        const newText = inputRef.current.value.replace(
          `${constants.SAMPLE_BITLY_URL}`,
          ''
        );
        setValue(`${name}`, newText);
        onChange(transformText(newText));
      } else {
        onChange(transformText(inputRef.current.value));
      }
    }, [insertingURLFollowup]);

    useEffect(() => {
      let matchString = '';
      if (
        get(smsDetails, '[0].URL.[0].shortURL') &&
        oldWatchValues &&
        oldWatchValues.fullURL === ''
      ) {
        matchString = get(smsDetails, '[0].URL.[0].shortURL');
      } else {
        matchString =
          constants.SAMPLE_BITLY_URL !== oldWatchValues?.shortURL
            ? oldWatchValues?.shortURL
            : constants.SAMPLE_BITLY_URL;
      }
      const param =
        name === 'smsContent' ? insertingURLMain : insertingURLFollowup;
      const fn =
        name === 'smsContent' ? setInsertingURLMain : setInsertingURLFollowup;
      if (matchString) {
        if (
          inputRef?.current?.value?.includes(constants.SAMPLE_BITLY_URL) ||
          inputRef?.current?.value?.includes(matchString)
        ) {
          fn(true);
        } else {
          fn(false);
        }
      }
    }, [inputRef?.current?.value]);

    useEffect(() => {
      let matchString =
        constants.SAMPLE_BITLY_URL !== oldWatchValues?.shortURL
          ? oldWatchValues?.shortURL
          : constants.SAMPLE_BITLY_URL;
      let newValue = '';
      if (!matchString) {
        matchString = constants.SAMPLE_BITLY_URL;
      }
      if (watchValues.shortURL) {
        newValue = value.replace(
          new RegExp(`\\[${matchString}\\]`, 'g'),
          `[${watchValues.shortURL}]`
        );
      } else {
        newValue = value.replace(
          new RegExp(`\\[${matchString}\\]`, 'g'),
          `[${constants.SAMPLE_BITLY_URL}]`
        );
      }
      onChange(newValue);
    }, [watchValues]);

    const addEmoji = e => {};

    const handleTextLink = (e, str) => {
      const cursor = inputRef.current.selectionStart || cursorPosition;
      const cursorEnd = inputRef.current.selectionEnd || cursorPosition;
      const value = inputRef.current.value;
      const identifier = str.trim();

      if (e.target.innerText === 'Insert URL') {
        if (name === 'smsContent') {
          setInsertingURLMain(true);
        } else if (name === 'smsContentFollowUp') {
          setInsertingURLFollowup(true);
        }
      }

      setCurrentIdentifier(str.trim());

      const matchList = findAllTags(
        inputRef.current?.value,
        new RegExp(identifier, 'g')
      );

      if (checkIfWord(cursor, matchList)) {
        return null;
      }

      const text = value.slice(0, cursor) + str + value.slice(cursorEnd);
      if (text && text.length > parseInt(maxLength)) {
        return null;
      }
      onChange(transformText(text, str));
      //Codes added for the new cursor
      const newCursor = cursor + str.length;
      setTimeout(() => {
        inputRef.current.setSelectionRange(newCursor, newCursor);
        setCursorPosition(null);
        inputRef.current.focus();
      }, 10);
    };

    const charCountAdjust =
      (value.match(new RegExp(`[${currentIdentifier}]`, 'g')) || []).length * 2;

    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
      if (
        !maxLength ||
        (maxLength && newValue.length <= parseInt(maxLength) + charCountAdjust)
      ) {
        onChange(newValue);
      }
    };

    const onAddUrlSuggestion = (id, display) => {
      if (
        (name === 'smsContent' && !insertingURLMain) ||
        (name === 'smsContentFollowUp' && !insertingURLFollowup)
      ) {
        if (name === 'smsContent') {
          setInsertingURLMain(true);
        } else if (name === 'smsContentFollowUp') {
          setInsertingURLFollowup(true);
        }
      }
    };

    const textLength =
      value && value.length
        ? value.includes(constants.SAMPLE_BITLY_URL)
          ? value.length + optOutTextLength - 2
          : value.length + optOutTextLength
        : 0;
    const contentStats = getGSMandUnicodeStats(
      optOutText ? value + ` ${optOutText}` : value, watchValues.shortURL
    );

    const getMentionsList = () => {
      const list = [
        <Mention
          data={[{ id: 'FName', display: 'FName' }]}
          trigger="@"
          markup="[__id__]"
          displayTransform={(id, display) => `${display}`}
          style={{
            backgroundColor: '#cee4e5'
          }}
          appendSpaceOnAdd
        />
      ];
      if (
        (name === 'smsContent' && !insertingURLMain) ||
        (name === 'smsContentFollowUp' && !insertingURLFollowup)
      ) {
        list.push(
          <Mention
            data={[
              {
                id: watchValues.shortURL
                  ? watchValues.shortURL
                  : `${constants.SAMPLE_BITLY_URL}`,
                display: `URL`
              }
            ]}
            trigger="@"
            markup="[__id__]"
            // {watchValues.shortURL != constants.SAMPLE_BITLY_URL ? '__id__' : '__id__'}
            displayTransform={(id, display) => `${id}`}
            style={{
              backgroundColor: '#cee4e5'
            }}
            appendSpaceOnAdd
            onAdd={onAddUrlSuggestion}
          />
        );
      }

      if (isDynamicCoupon) {
        list.push(
          <Mention
            data={[{ id: 'Coupon', display: 'Coupon' }]}
            trigger="@"
            markup="[__id__]"
            displayTransform={(id, display) => `${display}`}
            style={{
              backgroundColor: '#cee4e5'
            }}
            appendSpaceOnAdd
          />
        );
      }
      return list;
    };

    return (
      <div
        className={`form-group emoji-custom-input enhanced-textarea enhanced-textarea-${flow}`}
      >
        <label htmlFor={name} className="absolute-label">
          {label}
          {labelTooltip && (
            <>
              <span
                className="icon"
                style={{ cursor: 'pointer' }}
                data-tip={labelTooltip}
                data-for={label}
              >
                &nbsp;<i className="fas fa-question-circle"></i>
              </span>
              <ReactTooltip
                id={label}
                place="right"
                type="info"
                multiline={true}
                className="cw-tooltip cw-email-tooltip"
              />
            </>
          )}
          {subText && (
            <span className="email-label-subtext d-block">{subText}</span>
          )}
        </label>

        {CustomHelpComponent ? (
          <CustomHelpComponent {...customHelpComponentProps} />
        ) : (
          ''
        )}

        <div
          className={classNames('input-group opt-out-combined', {
            'is-invalid': hasError
          })}
        >
          <MentionsInput
            value={value}
            name={name}
            onChange={handleChange}
            className={classNames(`react-mentions-${flow}`)}
            placeholder={
              'Please enter SMS body content and use @ to add dynamic fields'
            }
            style={defaultStyle}
            a11ySuggestionsListLabel={'Suggested mentions'}
            inputRef={e => {
              ref(e);
              inputRef.current = e;
            }}
          >
            {getMentionsList()}
          </MentionsInput>

          {showEmojis && (
            <div className="input-group-append">
              <span className="position-absolute text-secondary emoji-custom-input__count">
                {value.length - charCountAdjust}/{maxLength}
              </span>
              <span
                className={classNames('input-group-text cw-icon cw-icon--cal')}
                id="cw-icon--cal"
                onClick={() => {
                  setIsEmojisSelectorOpen(!isEmojisSelectorOpen);
                }}
              >
                <i className="far fa-smile"></i>
              </span>
            </div>
          )}
          {showCharactorCount && detectUnicodeContent ? (
            <span className="position-absolute text-secondary charcountinput__count">
              {getCharCountText(contentStats)}
            </span>
          ) : (
            <span className="position-absolute text-secondary charcountinput__count">
              {textLength}/{constants.SMS_BODY_CONTENT_MAX_LENGTH}
            </span>
          )}
          <div className="opt-out-box">{optOutText}</div>
        </div>

        <div className="d-flex justify-content-between mt-1">
          <div>
            <LinkButton
              className="emoji-custom-input__textlink"
              onClick={e => handleTextLink(e, 'FName ')}
            >
              Insert first name
            </LinkButton>

            <LinkButton
              className="emoji-custom-input__textlink ml-2"
              onClick={e => {
                if (
                  (name === 'smsContent' && !insertingURLMain) ||
                  (name === 'smsContentFollowUp' && !insertingURLFollowup)
                ) {
                  handleTextLink(
                    e,
                    get(smsDetails, '[0].URL.[0].shortURL', '')
                      ? `${get(smsDetails, '[0].URL.[0].shortURL')}`
                      : `${constants.SAMPLE_BITLY_URL} `
                  );

                  // handleTextLink(e, `${constants.SAMPLE_BITLY_URL} `);
                }
              }}
            >
              Insert URL
            </LinkButton>

            {isDynamicCoupon && (
              <LinkButton
                className="emoji-custom-input__textlink ml-2"
                onClick={e => handleTextLink(e, 'Coupon ')}
              >
                Insert dynamic coupon
              </LinkButton>
            )}

            {placeholderTooltipText && (
              <>
                <i
                  className="fas fa-info-circle ml-2"
                  data-for="value-tooltip"
                  data-tip={placeholderTooltipText}
                  style={{
                    cursor: 'pointer',
                    fontSize: 'var(--text-base-size-sm)'
                  }}
                ></i>
                <ReactTooltip
                  id="value-tooltip"
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
                />
              </>
            )}
          </div>

          <div className="emoji-custom-input__helparea">
            <div className="emoji-custom-input__helptext">{helpText}</div>
          </div>
        </div>

        {detectUnicodeContent && <CharecterCountAlert stats={contentStats} />}
        {!detectUnicodeContent &&
          textLength > constants.SMS_BODY_CONTENT_MAX_LENGTH && (
            <div className="mt-10">
              <Alert
                alertType="warningMessageWithIcon"
                textMessage={`You have exceeded max length of 160 characters. Your message will be truncated into multiple texts and you will be charged for multiple SMS. Read character length guidelines below.`}
                showComponent={true}
              />
            </div>
          )}

        <div>
          <FieldError
            errors={errors}
            name={name}
            className="emoji-custom-input__error-mesg"
          />
        </div>

        {isEmojisSelectorOpen && (
          <div className="emoji-custom-input__picker">
            <Picker
              data={data}
              onEmojiSelect={addEmoji}
              onClickOutside={() => {
                inputRef.current.focus();
                setIsEmojisSelectorOpen(false);
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
export default Input;
